import React from "react";
import useCountries from "../../../hooks/useCountries";

import { Dropdown } from "primereact/dropdown";
import ItemWithCountryFlag from "../ItemWithCountryFlag";
import { t } from "i18next";

export default ({ id, value, onChange }) => {
  const countries = useCountries()[0].sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );

  return (
    <Dropdown
      id={id}
      value={value}
      options={countries}
      appendTo={document.body}
      optionValue="alphaCode"
      optionLabel="displayName"
      placeholder={t("Select a Country")}
      itemTemplate={({ alphaCode, displayName }) => (
        <ItemWithCountryFlag
          alphaCode={alphaCode}
          display={`${displayName} (${alphaCode})`}
        />
      )}
      onChange={({ value }) => onChange(value)}
      filterBy="displayName"
      filter
    />
  );
};
