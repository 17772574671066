import React from "react";
import { ApiContext } from "../../../../providers/ApiProvider";
import { NotificationContext } from "../../../../providers/NotificationProvider";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import CommonStyles from "../../../CommonStyles.module.scss";
import { Dialog } from "primereact/dialog";
import GenericMaintenanceScreen from "./GenericMaintanceScreen";
import FloatLabel from "../../../utils/FloatLabel";
import useErrorHandler from "../../../../hooks/useErrorHandler";
import CountrySelector from "../../../utils/selectors/CountrySelector";

import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import XIcon from "../../../../assets/images/XIcon.svg";
import { t } from "i18next";

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext);
  const errorHandler = useErrorHandler(
    t("Something went wrong on managing the data")
  );

  const [loading, setLoading] = React.useState(false);
  const [triedOnce, setTriedOnce] = React.useState(false);

  const [name, setName] = React.useState("");
  const [country, setCountry] = React.useState();

  React.useEffect(refresh, [id]);

  function refresh() {
    setTriedOnce(false);
    setLoading(true);
    if (id) {
      api
        .getPort(id)
        .then(({ data }) => {
          setName(data.name);
          setCountry(data.country.alphaCode);
          setLoading(false);
        })
        .catch(errorHandler);
    } else {
      setName("");
      setCountry(undefined);
      setLoading(false);
    }
  }

  function finish() {
    toast.success(t("Port saved successfully"), name);
    onHide();
    notify();
  }

  function send() {
    setTriedOnce(true);
    if (name && country) {
      const payload = { name, country };
      setLoading(true);
      if (id) {
        api.editPort(id, payload).then(finish).catch(errorHandler);
      } else {
        api.addPort(payload).then(finish).catch(errorHandler);
      }
    }
  }

  function deletedToast() {
    toast.success(t("Country deleted successfully"), name);
    onHide();
    notify();
  }

  function handleDelete({ id, t }) {
    api.deletePort(id).then(deletedToast).catch(errorHandler);
    toast.dismiss(t.id);
  }

  function askDelete() {
    toast((_t) => (
      <div className="deleteToastAdm">
        <h1>
          {t("Are you sure you want to delete")}{" "}
          <b>
            {name} ({country})
          </b>
          ?
        </h1>
        <p>{t("This is a one-way action!")}</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="btn btn--secondary"
            onClick={() => toast.dismiss(_t.id)}
          >
            {t("Cancel")}
          </button>
          <button
            style={{ marginLeft: "5px" }}
            className="btn btn--alert center"
            onClick={() => handleDelete({ id, _t })}
          >
            {t("Delete")}
          </button>
        </div>
      </div>
    ));
  }

  const header = (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{ margin: "0" }}>
            {id ? `${t("Editing")} ${name} (${country})` : t("Creating")}
          </h1>
          <button className="xIconButton center" onClick={onHide}>
            <img src={XIcon} alt="" />
          </button>
        </div>
        <p>
          {id
            ? t("Change the name and the country")
            : t("Write the name and select the country")}
        </p>
      </div>
    </>
  );

  const body = (
    <>
      <FloatLabel id="name" label={t("Name")}>
        <InputText
          value={name}
          onChange={({ target }) => setName(target.value)}
        />
      </FloatLabel>
      {triedOnce && !name && (
        <span className={CommonStyles.RedText}>
          {t("Please provide a name")}
        </span>
      )}
      <div style={{ marginTop: 8, marginBottom: 8 }}>
        <label className="selectLabelAdm" htmlFor="country">
          {t("Country")}:
        </label>
        <br />
        <CountrySelector id="country" value={country} onChange={setCountry} />
      </div>
    </>
  );

  const footer = (
    <>
      {id && !loading ? (
        <>
          <div style={{ width: "100%", display: "flex" }}>
            <button className="btn btn--alert center" onClick={askDelete}>
              {t("Delete")}
            </button>
            <button className="btn btn--primary" onClick={send}>
              {t("Save")}
            </button>
          </div>
        </>
      ) : (
        <>
          {!loading && (
            <button className="btn btn--primary" onClick={send}>
              {t("Create")}
            </button>
          )}
        </>
      )}
    </>
  );

  return (
    <Dialog
      className="modalAdmWrapper"
      onHide={onHide}
      visible={visible}
      header={header}
      footer={footer}
      draggable={false}
    >
      {loading ? (
        <div className="dfCenter">
          <ReactLoading
            type={"spinningBubbles"}
            color={"#31c5f4"}
            height={"40px"}
            width={"40px"}
          />
        </div>
      ) : (
        body
      )}
    </Dialog>
  );
}

export default function PortMaintenance() {
  const api = React.useContext(ApiContext);

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  );

  function filterData(data, filterTable) {
    const dataFiltered =
      filterTable.length > 0
        ? data.filter(
            (e) =>
              (e.name &&
                e.name
                  .toLowerCase()
                  .replace(/[\s-]/g, "")
                  .includes(filterTable.replace(/[\s-]/g, "").toLowerCase())) ||
              (e.country &&
                e.country.displayName
                  .toLowerCase()
                  .replace(/[\s-]/g, "")
                  .includes(filterTable.replace(/[\s-]/g, "").toLowerCase())) ||
              (e.country &&
                e.country.alphaCode
                  .toLowerCase()
                  .replace(/[\s-]/g, "")
                  .includes(filterTable.replace(/[\s-]/g, "").toLowerCase()))
          )
        : data;

    return dataFiltered;
  }

  return (
    <GenericMaintenanceScreen
      dataProvider={api.getPorts}
      renderEditDialog={dialog}
      filterData={filterData}
    >
      <Column field="id" header={t("Id")} sortable />
      <Column field="name" header={t("Name")} sortable />
      <Column
        field="country.displayName"
        header={t("Country")}
        body={({ country }) => `${country.displayName} (${country.alphaCode})`}
        sortable
      />
    </GenericMaintenanceScreen>
  );
}
