import React from "react";
import i18n from "../../hooks/translations/translation";

const LanguageSelector = () => {
  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  };

  return (
    <select
      style={{ width: "100px" }}
      className="translate"
      id="translate"
      onChange={handleLanguageChange}
      value={i18n.language}
    >
      <option value="en">English</option>
      <option value="fr">French</option>
      <option value="ptBr">Portuguese</option>
    </select>
  );
};

export default LanguageSelector;
