import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../../../../redux/actions";
import MyPublicFormulas from "../../../utils/MyPublicFormulas";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import { auth } from "../../../../services/firebase";
import ReactLoading from "react-loading";
import firebase from "firebase/app";
import { OWN_FORMULAS } from "../../../../data";
import ManThinking from "../../../../assets/images/manThinking.jpg";
import InfoIcon from "../../../../assets/images/infoIcon.svg";
import NoResultsFound from "../../../../assets/images/noResultsFound.svg";
import SearchIcon from "../../../../assets/images/searchbarIcon.png";
import PlusIcon from "../../../../assets/images/plusIcon.png";
import questionMinionsImg from "../../../../assets/images/questionMinionsImg.png";
import { useErrorHandler } from "../../../../hooks";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();

export function FormulaListPage({ history }) {
  const { t } = useTranslation();
  const firestore = firebase.firestore();
  const collection = firestore.collection("formulas");
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const formulasToCalculate = useSelector((state) => state.compare.formulas);
  const [checkedPublicFormulas, setCheckedPublicFormulas] = useState(true);
  const [checkedMyFormulas, setCheckedMyFormulas] = useState(true);
  const [isFilteringOptions, setIsFilteringOptions] = useState(false);
  const [myFormulas, setMyFormulas] = useState([]);
  const [checked, setChecked] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [toggleChecked, setToggleChecked] = useState(false);
  const [onReset, setOnReset] = useState(false);
  const [formulas, setFormulas] = useState([]);
  const [formulasList, setFormulasList] = useState([]);
  const [explainingFormulas, setExplainingFormulas] = useState(false);
  const notFoundText = t(
    "Try reset the filter options or search for a different formula name"
  );
  const errorHandler = useErrorHandler("Could not share your comparison");
  const [dropdownSelectedPOE, setDropdownSelectedPOE] = useState(
    cookies.get("POE") === undefined ? "" : cookies.get("POE")
  );
  const [dropdownSelectedPOP, setDropdownSelectedPOP] = useState(
    cookies.get("POP") === undefined ? "" : cookies.get("POP")
  );
  const [dropDownSelectedDestination, setDropdownSelectedDestination] =
    useState(cookies.get("FD") === undefined ? "" : cookies.get("FD"));

  const handleChangeCheckbox = (id) => {
    let checkArray = checked || [];

    if (checkArray.includes(id)) {
      setChecked([...checked.filter((e) => e !== id)]);
      dispatch(Actions.compRmFormula(id));
    } else {
      setChecked([...checked, id]);
      const formulaToCompare = formulasList.find(
        (formula) => formula.id === id
      );
      dispatch(Actions.compAddFormula(formulaToCompare));
    }
  };

  const handleChangeMyFormulas = () => {
    setCheckedMyFormulas(!checkedMyFormulas);
  };

  const handleChangePublicFormulas = () => {
    setCheckedPublicFormulas(!checkedPublicFormulas);
  };

  function deleteMyFormulaUnit({ id, name }) {
    const deleteFormula = formulasList.filter((e) => e.id !== id);
    setFormulasList(deleteFormula);

    collection
      .doc(id)
      .delete()
      .then(() => {
        OWN_FORMULAS.forget();
        localStorage.removeItem("@fcc/ownFormulas");
        dispatch(Actions.clearFormula());
      });
    toast.success(name + " was discarded");
  }

  const compareFormulas = () => {
    let search = `&entry=${
      dropdownSelectedPOE?.id ? dropdownSelectedPOE.id : ""
    }&processing=${
      dropdownSelectedPOP?.id ? dropdownSelectedPOP.id : ""
    }&destination=${
      dropDownSelectedDestination?.id ? dropDownSelectedDestination.id : ""
    }`;

    firestore
      .collection("compareShares")
      .add({ formulasToCalculate })
      .then((ref) =>
        history.push(`${path}s/compare/shared?id=${ref.id}${search}`)
      )
      .catch(errorHandler);
  };

  const Checkbox = ({ label, id, onChange, class: className }) => {
    return (
      <label className={className}>
        <input type="checkbox" checked={id} onChange={onChange} />
        <p>{label}</p>
      </label>
    );
  };

  function showFilteringOptions() {
    setIsFilteringOptions(!isFilteringOptions);
  }

  function closeFilteringOptions() {
    setIsFilteringOptions(!isFilteringOptions);
  }

  function handleChangeToggle() {
    setToggleChecked(!toggleChecked);
  }

  function resetFiltering() {
    setToggleChecked(false);
    setOnReset(!onReset);
    setCheckedMyFormulas(true);
    setCheckedPublicFormulas(true);
  }

  const getFormulas = async () => {
    setIsLoading(true);

    const publicFormula = await firestore
      .collection("formulas")
      .where("public", "==", true)
      .get();

    const formulasPublic = publicFormula.docs.map((it) => ({
      id: it.id,
      ...it.data(),
    }));

    const myFormula = await firestore
      .collection("formulas")
      .where("email", "==", auth.currentUser?.email)
      .get();

    const formulasMy = myFormula.docs.map((it) => ({
      id: it.id,
      ...it.data(),
    }));

    const formulasMyPublic = [
      ...formulasMy.filter((e) => e.public == false),
      ...formulasPublic,
    ];

    setFormulas(formulasMyPublic);
    setFormulasList(formulasMyPublic);
    setIsLoading(false);
  };

  useEffect(() => {
    getFormulas();
  }, []);

  useEffect(() => {
    let filteredList = formulas;

    filteredList = filteredList.filter(
      (e) =>
        (checkedMyFormulas && e.public === false) ||
        (checkedPublicFormulas && e.public === true)
    );

    filteredList =
      inputValue !== ""
        ? (filteredList = filteredList.filter((e) => {
            const removeCharsInputValue = inputValue.replace(/-/g, " ");
            const removeCharsFormulaName = e.name.replace(/-/g, " ");
            return (
              removeCharsInputValue &&
              removeCharsFormulaName
                .toLocaleLowerCase()
                .indexOf(removeCharsInputValue.toLocaleLowerCase()) >= 0
            );
          }))
        : filteredList;

    setFormulasList(filteredList);
  }, [checkedPublicFormulas, checkedMyFormulas, inputValue]);

  return (
    <>
      <Toaster />
      <div className="all--wrapper">
        <div className="formulasList">
          <div className="myFormulasHeader">
            <div className="title">
              <h1>{t("Formulas")}</h1>
              <button
                className="btn center"
                onFocus={() => setExplainingFormulas(true)}
                onBlur={() => setExplainingFormulas(false)}
              >
                <img src={InfoIcon} alt="" />
              </button>
            </div>
            {explainingFormulas && (
              <div className="explainingFormulasInfo">
                <img src={questionMinionsImg} alt="" />
                <div>
                  <h1>{t("Welcome to formula list page!")}</h1>
                  <p>
                    {t(
                      "The publics and your privates formulas will be displayed here."
                    )}
                  </p>
                  <p>
                    <i>
                      {t(
                        "You can easily create another formula, filter or discard them."
                      )}
                    </i>
                  </p>
                </div>
              </div>
            )}
            <div>
              <div className="myFormulasHeaderStart">
                <Link className="Link" to="/my/formula/create">
                  <button
                    className="btn btn--primary center"
                    onClick={() => dispatch(Actions.clearFormula())}
                  >
                    <img
                      style={{ maxWidth: "23px", marginRight: "10px" }}
                      src={PlusIcon}
                      alt=""
                    />
                    <p>{t("New Formula")}</p>
                  </button>
                </Link>
                <button
                  onClick={() => showFilteringOptions()}
                  className="btn btn--primary btnOrangeBg"
                  style={{ maxWidth: "165px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M7 11h10v2H7zM4 7h16v2H4zm6 8h4v2h-4z"></path>
                  </svg>
                  <p>{t("Filtering Options")}</p>
                </button>
                {checked.length > 1 ? (
                  <div className="menuSelects desktop">
                    <button
                      onClick={() => compareFormulas()}
                      className="btn btn--blue compareBtn selectFormulasBtnAnimated"
                    >
                      {t("Compare Formulas")}
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                ) : (
                  <div
                    style={{ "align-items": "center", display: "flex" }}
                    className="desktop"
                  >
                    <p style={{ fontSize: "14px", margin: "5px" }}>
                      {t(
                        "💡 Select different fertilizer formulas to compare and find the best price."
                      )}
                    </p>
                  </div>
                )}
              </div>

              <div className="searchAndFilter desktop">
                <div className="dropdownSelect">
                  <input
                    id="searchbarMyFormulas"
                    autoComplete="off"
                    spellCheck="false"
                    className="searchBarInput"
                    type="text"
                    placeholder={t("Search a formula name")}
                    onChange={(e) => setInputValue(e.target.value)}
                  />

                  <div className="searchbarIcon">
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="searchAndFilter mobile">
              <div className="dropdownSelect">
                <input
                  id="searchbarMyFormulas"
                  autoComplete="off"
                  spellCheck="false"
                  className="searchBarInput"
                  type="text"
                  placeholder={t("Search a formula name")}
                  onChange={(e) => setInputValue(e.target.value)}
                />

                <div className="searchbarIcon">
                  <img src={SearchIcon} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="myFormulasMain">
            {isFilteringOptions && (
              <div className="modal">
                <div className="modalWrapper" style={{ height: "auto" }}>
                  <div
                    className="imgFilteredOptions"
                    style={{
                      "max-width": "310px",
                      margin: "32px",
                    }}
                  >
                    <img src={ManThinking} alt="" />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div className="filteringOptions">
                      <div className="filteringOptionsInside">
                        <h1>{t("Filtering Options")}</h1>
                        <p style={{ fontSize: "14px", color: "#444" }}>
                          {t(
                            "Here you can easily configure the filtering for your formula listing"
                          )}
                        </p>
                      </div>
                      <div className="filteringOptionsInside">
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "116px",
                          }}
                        >
                          <Toggle
                            checked={toggleChecked}
                            defaultChecked={toggleChecked}
                            icons={false}
                            className="customToggle"
                            onChange={() => handleChangeToggle()}
                          />
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#444",
                              marginLeft: "5px",
                            }}
                          >
                            {t("Cards Form")}
                          </span>
                        </label>
                      </div>
                      <div className="filteringOptionsInside">
                        <h1>{t("Formula Type")}</h1>
                        <div className="div">
                          <div className="div2">
                            <Checkbox
                              class={"checkbox"}
                              label={t("My Formulas")}
                              id={checkedMyFormulas}
                              onChange={() => handleChangeMyFormulas()}
                            />
                          </div>
                          <div className="div2">
                            <Checkbox
                              class={"checkbox"}
                              label={t("Public Formulas")}
                              id={checkedPublicFormulas}
                              onChange={() => handleChangePublicFormulas()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="filteringOptionsInside">
                        <div className="div">
                          <button
                            className="btn btn--secondary"
                            onClick={() => resetFiltering()}
                          >
                            {t("Reset Filter")}
                          </button>
                          <button
                            className="btn btn--primary"
                            onClick={() => closeFilteringOptions()}
                          >
                            {t("OK")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={
                toggleChecked
                  ? `formulasMyPublicWrapper`
                  : "formulasMyPublicWrapperList"
              }
            >
              {formulasList.length > 0 ? (
                formulasList
                  .sort(function (a, b) {
                    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                  })
                  .map(({ id, name, products, public: isPublic }) => (
                    <MyPublicFormulas
                      key={id}
                      id={id}
                      name={name}
                      products={products}
                      public={isPublic}
                      handleChangeCheckbox={handleChangeCheckbox}
                      checked={
                        checked.filter((e) => e === id)[0] ? true : false
                      }
                      myFormulas={myFormulas}
                      setMyFormulas={setMyFormulas}
                      isToggled={toggleChecked}
                      deleteMyFormulaUnit={deleteMyFormulaUnit}
                    />
                  ))
              ) : isLoading ? (
                <div className="dfCenter">
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#31c5f4"}
                    height={"50px"}
                    width={"50px"}
                  />
                  <h1
                    style={{
                      marginTop: "20px",
                      fontSize: "16px",
                      color: "#555",
                    }}
                  >
                    {t("Loading Formulas...")}
                  </h1>
                </div>
              ) : (
                <div className="noResultsFound">
                  <img src={NoResultsFound} alt="" />
                  <h1>{t("No results found!")}</h1>
                  <p>{notFoundText}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
