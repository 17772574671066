import React from "react";

import { useSelector } from "react-redux";
import useFormulaResults from "./useFormulaResults";
import useProductTypes from "./useProductTypes";

export default (
  editing,
  formula,
  dropdownSelectedPOE,
  destinationId,
  placeOfBlendingId
) => {
  const productTypes = useProductTypes();

  let costProducts = [];

  if (editing) costProducts = useSelector((state) => state.formula);
  else {
    formula.products.map((e, index) =>
      productTypes.find((element) => {
        if (element.id === e.type) {
          const product = {
            bankInterestRate: 0,
            color: e.isFiller ? "#9146fa" : "#49b2f5",
            demurrage: 0,
            demurrageDuration: 0,
            demurragePrice: 0,
            loanCashoutDuration: 0,
            maxFob: element.maxFob,
            minFob: element.minFob,
            passageMode: 1,
            percentage: e.percentage,
            placeOfBlending: 1,
            port: dropdownSelectedPOE,
            seaFreight: 0,
            storageCost: 0,
            storageDuration: 0,
            transit: "NONE",
            type: e.type,
          };
          formula.products[index] = product;
        }
      })
    );
    costProducts = formula;
  }

  const results = useFormulaResults(
    [costProducts],
    destinationId,
    placeOfBlendingId
  );

  return results.length ? results[0] : undefined;
};
