import React, { useMemo, useState, useEffect } from "react";
import SelectDropdown from "../../utils/SelectDropdown";
import {
  usePorts,
  usePlacesOfBlending,
  useFinalDestinations,
  useProductTypes,
} from "../../../hooks";
import firebase from "firebase/app";
import { auth } from "../../../services/firebase";
import NoFormulasImage from "../../../assets/images/noFormulasImage.svg";
import AirplaneImg from "../../../assets/images/AirplanesImg.png";
import PrinterIcon from "../../../assets/images/printerIcon.png";
import ShareIcon from "../../../assets/images/shareIcon.png";
import NPKBagIcon from "../../../assets/images/fertilizerIcon4.png";
import fertilizer from "../../../assets/images/fertilizerIcon3.png";
import NPKBagIcon2 from "../../../assets/images/fertilizerIcon5.png";
import FilterIcon from "../../../assets/images/FilterIcon.png";
import DetailIcon from "../../../assets/images/detailIcon.png";
import BusinessImg from "../../../assets/images/business.svg";
import IfdcLogo from "../../../assets/images/ifdc_logo.png";
import UsaidLogo from "../../../assets/images/usaid_logo.png";
import UemoaLogo from "../../../assets/images/uemoa_logo.jpg";
import FeedIFuture from "../../../assets/images/feedifuture.png";
import EcoWas from "../../../assets/images/ECOWAS.jpg";
import CilssLogo from "../../../assets/images/cilss_logo.png";
import ArrowTwo from "../../../assets/images/arrowTwo.png";
import XIcon from "../../../assets/images/XIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import Styles from "./FormulaPicker.module.scss";

import { Category, ResultsTable } from "../../ResultsTable";
import { Checkbox } from "primereact/checkbox";
import {
  sumKeys,
  sumResultsApplyingPercentage,
} from "../../../calculation/results-utils";
import ShareDialog from "./ShareDialog";
import toast, { Toaster } from "react-hot-toast";
import useFormulaResults from "../../../hooks/useFormulaResults";
import {
  FormatOptions,
  formatTown,
  formatDestination as formatDestinationName,
} from "../../../formatter";
import { useDeepCompareMemoized } from "../../../utils";
import useSearchParam from "../../../hooks/useSearchParam";
import {
  byId,
  allOwn,
  allPublic,
  queryRemoteFormulas,
} from "../../../hooks/queryRemoteFormulas";
import { startEdit, TemporaryEditor } from "./TemporaryEditor";
import * as Actions from "../../../redux/actions";
import Tooltip from "rc-tooltip";
import { useErrorHandler } from "../../../hooks";
import { useTranslation } from "react-i18next";

import Cookies from "universal-cookie";
import SelectDropdownMobile from "../../utils/SelectDropdownMobile";
import useLandTransports from "../../../hooks/useLandTransports";
import doesRouteExist from "../../utils/functions/doesRouteExist";
const cookies = new Cookies();

export default function CompareFormulas() {
  const dispatch = useDispatch();
  const firestore = firebase.firestore();
  const [dropdownSelectedPOE, setDropdownSelectedPOE] = useState(
    cookies.get("POE") === undefined ? "" : cookies.get("POE")
  );
  const [dropdownSelectedPOP, setDropdownSelectedPOP] = useState(
    cookies.get("POP") === undefined ? "" : cookies.get("POP")
  );
  const [dropDownSelectedDestination, setDropdownSelectedDestination] =
    useState(cookies.get("FD") === undefined ? "" : cookies.get("FD"));
  const [optionsSelectedPOE, setOptionsSelectedPOE] = useState(false);
  const [optionsSelectedPOP, setOptionsSelectedPOP] = useState(false);
  const [checkboxSummary, setCheckoxSummary] = useState(false);
  const [optionsSelectedDestination, setOptionsSelectedDestination] =
    useState(false);
  const [formulas, setFormulas] = useState([]);
  const [hasFormulasCompared, setHasFormulasCompared] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [buttonDetails, setButtonDetails] = useState(true);
  const [placesOfBlending] = usePlacesOfBlending();
  const [showDetails, setShowDetails] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isPlacesClicked, setIsPlacesClicked] = useState(false);
  const [destinations] = useFinalDestinations();
  const [filter, setFilter] = React.useState(Object.values(Category));
  const [isSecondActionsOpen, setIsSecondActionsOpen] = useState(false);
  const formulasCompRedux = useSelector((state) => state.compare.formulas);
  const [formulasToCalculateAux, setNewResults] = useState([]);
  const productTypes = useProductTypes();
  const [indexHeader, setIndexHeader] = useState([]);
  const [productsSummary, setProductsSummary] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [controlFoundPOE, setControlFoundPOE] = useState(false);
  const [controlFoundPOP, setControlFoundPOP] = useState(false);
  const [controlFoundDES, setControlFoundDES] = useState(false);
  const [flagPOEtable, setFlagPOEtable] = useState(0);
  const [flagPOPtable, setFlagPOPtable] = useState(0);
  const [flagDEStable, setFlagDEStable] = useState(0);
  const [shareId, setShareId] = React.useState();
  const [onGetNewCosts, setOnGetNewCosts] = React.useState(false);
  const [onChangeInput, setOnChangeInput] = React.useState([]);
  const errorHandler = useErrorHandler("Could not share your comparison");

  const origin = useSearchParam("origin");
  const shared = origin === "shared";
  const ports = usePorts();
  const pickedFormulas = useSelector((state) => state.compare.formulas);
  const possibleFormulas = queryRemoteFormulas([allOwn(), allPublic()]);

  const { t } = useTranslation();

  function PickedFormulaItem({ index }) {
    const formula = pickedFormulas[index];

    return (
      <div
        className={`p-col-12 p-sm-6 p-md-4 p-lg-3 ${Styles.PickedFormulaItem}`}
      >
        <span className="p-overlay-badge">
          <div className="formulaCards cardsNotSelected">
            <img src={fertilizer} loading="eager" />
            <p style={{ "font-weight": "bold" }}>{formula.name}</p>
          </div>
          <span
            className="p-badge p-badge-warning"
            title="Remove from comparison"
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(Actions.compRmIndex(index))}
          >
            <i className="fa fa-trash" />
          </span>
        </span>
      </div>
    );
  }

  const formatPortOfEntry = (id) => {
    const port = ports[0].find(
      (it) => it.id === (id || dropdownSelectedPOE.id)
    );
    return port ? port.name + ` (${port.country.alphaCode})` : "Unknown";
  };

  const formatPlaceOfBlending = (id) => {
    const place = placesOfBlending.find(
      (it) => it.id === (id || dropdownSelectedPOP.id)
    );
    return place ? formatTown(place, [FormatOptions.COUNTRY_ALPHA]) : "Unknown";
  };

  const formatDestination = (id) => {
    const place = destinations.find(
      (it) => it.id === (id || dropDownSelectedDestination.id)
    );
    return place
      ? formatDestinationName(place, [FormatOptions.COUNTRY_ALPHA])
      : "Unknown";
  };

  const rawResults = useFormulaResults(
    onGetNewCosts ? formulasToCalculateAux : formulasCompRedux,
    dropDownSelectedDestination && dropDownSelectedDestination.id
      ? dropDownSelectedDestination.id
      : undefined,
    dropdownSelectedPOP && dropdownSelectedPOP.id
      ? dropdownSelectedPOP.id
      : undefined
  );

  const resultsDependency = useDeepCompareMemoized(rawResults);

  const results = useMemo(() => {
    return rawResults.map((it) => ({
      portOfEntry:
        flagPOEtable === 1
          ? dropdownSelectedPOE &&
            dropdownSelectedPOE.name +
              ` (${dropdownSelectedPOE.country.alphaCode})`
          : formatPortOfEntry(it.formula.portOfEntry),
      blending:
        flagPOPtable === 1
          ? dropdownSelectedPOP &&
            dropdownSelectedPOP.name +
              ` (${dropdownSelectedPOP.country.alphaCode})`
          : formatPlaceOfBlending(it.formula.blending),
      destination:
        flagDEStable === 1
          ? dropDownSelectedDestination &&
            dropDownSelectedDestination.town.name +
              ` (${dropDownSelectedDestination.town.country.alphaCode})`
          : formatDestination(it.formula.destination),
      ...sumResultsApplyingPercentage(it.results.map((innerIt) => innerIt.MAX)),
    }));
  }, [resultsDependency]);

  const remoteImmutableFormulas = shared
    ? formulasToCalculateAux
    : queryRemoteFormulas(
        formulasToCalculateAux.map((it) => byId(it.id)),
        true
      );

  const placesOfProcessing = usePlacesOfBlending();
  var selectListPOP = placesOfProcessing[0]
    .map((place) => {
      return place.name + ` (${place.country.alphaCode})`;
    })
    .sort();

  const finalDestinations = useFinalDestinations();
  var selectListFD = finalDestinations[(0, 0)]
    .map((destination) => {
      return destination.town.name + ` (${destination.town.country.alphaCode})`;
    })
    .sort();

  var selectListPOE = ports[0]
    .map((port) => port.name + ` (${port.country.alphaCode})`)
    .sort();

  const [landTransports] = useLandTransports();

  const handleChangeDropdown = (value, setValue, myId) => {
    if (myId) {
      let routeExists = true;
      if (myId === "searchbarPOE") {
        setFlagPOEtable(1);
        cookies.set("POE", value, {
          path: "/",
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
        });
        routeExists = doesRouteExist(
          value,
          dropDownSelectedDestination,
          landTransports
        );
        // } else if (myId === "searchbarPOP") {
        //   setFlagPOPtable(1);
        //   cookies.set("POP", value, {
        //     path: "/",
        //     expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
        //   });
        // }
      } else {
        setFlagDEStable(1);
        cookies.set("FD", value, {
          path: "/",
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
        });
        routeExists = doesRouteExist(
          dropdownSelectedPOE,
          value,
          landTransports
        );
      }
      if (!routeExists) {
        toast((_t) => (
          <div className="route-missing-toast">
            <h1>{t("The selected route is not available.")}</h1>
            <p>{t("Please contact an administrator to create the route.")}</p>

            <button
              className="btn btn--secondary"
              onClick={() => toast.dismiss(_t.id)}
            >
              {t("Confirm")}
            </button>
          </div>
        ));
        setNewResults([]);
      }
    }

    setValue(value);
  };

  const handleChangeDropdownMobile = (value, setValue, myId) => {
    setValue(value);
  };

  function compareFormulas() {
    const routeExists = doesRouteExist(
      dropdownSelectedPOE,
      dropDownSelectedDestination,
      landTransports
    );
    if (routeExists) {
      setHasFormulasCompared(true);
    } else {
      toast((_t) => (
        <div className="route-missing-toast">
          <h1>{t("The selected route is not available.")}</h1>
          <p>{t("Please contact an administrator to create the route.")}</p>

          <button
            className="btn btn--secondary"
            onClick={() => toast.dismiss(_t.id)}
          >
            {t("Confirm")}
          </button>
        </div>
      ));
      setNewResults([]);
    }
    setIsOpenModal(false);
  }

  function cardSelected(formula) {
    const formulaAux = {
      ...formula,
      products: formula.products.map((product) => {
        return {
          ...product,
          port:
            dropdownSelectedPOE && dropdownSelectedPOE.id
              ? dropdownSelectedPOE.id
              : null,
          transit:
            dropdownSelectedPOE?.country?.alphaCode !==
            dropDownSelectedDestination?.town?.country?.alphaCode
              ? "DIRECT"
              : "NONE",
        };
      }),
    };
    dispatch(Actions.compAddFormula(formulaAux));
  }

  function cardSelectedMobile(formula) {
    if (formulasToCalculateAux.find((e) => e.id === formula.id)) {
      dispatch(Actions.compRmFormula(formula.id));
    } else {
      const formulaAux = {
        ...formula,
        products: formula.products.map((product) => {
          return {
            ...product,
            port:
              dropdownSelectedPOE && dropdownSelectedPOE.id
                ? dropdownSelectedPOE.id
                : null,
            transit:
              dropdownSelectedPOE?.country?.alphaCode !==
              dropDownSelectedDestination?.town?.country?.alphaCode
                ? "DIRECT"
                : "NONE",
          };
        }),
      };
      dispatch(Actions.compAddFormula(formulaAux));
    }
  }

  function openSummary(formula, index) {
    setCheckoxSummary(!checkboxSummary);
    setProductsSummary(formula.results);
    setIndexHeader(index);
  }

  function askGetNewCosts(it, index) {
    toast((t) => (
      <div className="deleteToastAdm">
        <h1 style={{ fontSize: 18 }}>
          This will overwrite your changes, do you want to proceed?
        </h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <button
            className="btn btn--secondary"
            onClick={() => toast.dismiss(t.id)}
          >
            {t("Cancel")}
          </button>
          <button
            style={{ marginLeft: "5px" }}
            className="btn btn--primary center"
            onClick={() => {
              toast.dismiss(t.id);
              getNewCosts(it, index);
            }}
          >
            {t("Confirm")}
          </button>
        </div>
      </div>
    ));
  }

  function getNewCosts(formula, index) {
    const formulaProducts = formula.formula.products.map((product, index) => {
      if (productTypes.find((e) => e.id === product.type)) {
        return {
          ...product,
          ...productTypes.find((e) => e.id === product.type),
        };
      }
    });

    const changeProductsValues = formulasToCalculateAux.map((e, idx) => {
      if (idx === index) {
        return {
          ...e,
          products: formulaProducts,
        };
      } else return e;
    });

    setNewResults(changeProductsValues);
    setOnGetNewCosts(true);
    setOnChangeInput([]);
    toast.success(`The costs of ${formula.formula.name} have been updated.`);
  }

  useEffect(() => {
    setNewResults(formulasCompRedux);
  }, [formulasCompRedux]);

  const headers = rawResults.map((it, index) => {
    return (
      <span className="p-overlay-badge">
        <span>
          <span>{it.formula.name}</span>
          <Tooltip placement="top" trigger="hover" overlay={t("Edit Formula")}>
            <span
              className="noPrint"
              onClick={() => startEdit(index, it)}
              style={{ cursor: "pointer" }}
            >
              <i
                className="fa fa-pencil-alt"
                style={{
                  color: "#000",
                  fontSize: ".95rem",
                  marginLeft: 5,
                }}
              />
            </span>
          </Tooltip>
          <Tooltip
            placement="top"
            trigger="hover"
            overlay={checkboxSummary ? "Close Summary" : "Show Summary"}
          >
            <span
              className="noPrint"
              onClick={() => openSummary(it, index)}
              style={{ cursor: "pointer" }}
            >
              <i
                className={checkboxSummary ? "fa fa-times" : "fa fa-bars"}
                style={{
                  color: "#000",
                  fontSize: ".95rem",
                  marginLeft: 5,
                }}
              />
            </span>
          </Tooltip>
          {!checkboxSummary && (
            <Tooltip placement="top" trigger="hover" overlay={"Get New Costs"}>
              <span
                className="noPrint"
                onClick={() => askGetNewCosts(it, index)}
                style={{ cursor: "pointer" }}
              >
                <i
                  className="pi pi-refresh"
                  style={{
                    color: "#000",
                    fontSize: "1rem",
                    marginLeft: 5,
                  }}
                />
              </span>
            </Tooltip>
          )}
        </span>
      </span>
    );
  });

  const headersSummary = rawResults.map((it, index) => {
    if (index == indexHeader) {
      return (
        <span className="p-overlay-badge">
          <span>
            <span>{it.formula.name}</span>
            <Tooltip
              placement="top"
              trigger="hover"
              overlay={t("Edit Formula")}
            >
              <span
                className="noPrint"
                onClick={() => startEdit(index)}
                style={{ cursor: "pointer" }}
              >
                <i
                  className="fa fa-pencil-alt"
                  style={{
                    color: "#000",
                    fontSize: ".95rem",
                    marginLeft: 5,
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip
              placement="top"
              trigger="hover"
              overlay={checkboxSummary ? "Close Summary" : "Show Summary"}
            >
              <span
                className="noPrint"
                onClick={() => openSummary(it, index)}
                style={{ cursor: "pointer" }}
              >
                <i
                  className={checkboxSummary ? "fa fa-times" : "fa fa-bars"}
                  style={{
                    color: "#000",
                    fontSize: ".95rem",
                    marginLeft: 5,
                  }}
                />
              </span>
            </Tooltip>
          </span>
        </span>
      );
    }
  });

  function renderTable() {
    return (
      <>
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            width: "200px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontSize: "20px", margin: "15px" }}>
              {t("Here are the your results")}:
            </p>
            <span
              className="desktop"
              style={{ fontSize: "16px", margin: "5px" }}
            >
              {t("Show Details")}:
              <Checkbox
                style={{ marginLeft: 2 }}
                checked={showDetails}
                onChange={(e) => setShowDetails(e.checked)}
              />
            </span>
          </div>
          {checkboxSummary ? (
            <ResultsTable
              filter={filter}
              headers={[
                headersSummary[indexHeader],
                ...productsSummary.map(
                  (it) =>
                    `${it.MAX.product.percentage}% ${it.MAX.product.type.name}`
                ),
              ]}
              headersTitle="Formula *"
              data={[
                onChangeInput.length == 0
                  ? results[indexHeader]
                  : onChangeInput[indexHeader],
                ...productsSummary
                  .map((it) => it.MAX)
                  .map((result) =>
                    Object.fromEntries(
                      Object.entries(result).filter(([k]) =>
                        sumKeys.includes(k)
                      )
                    )
                  ),
              ]}
              showDetails={showDetails}
              setOnChangeInput={setOnChangeInput}
              checkboxSummary={checkboxSummary}
              onGetNewCosts={onGetNewCosts}
              onChangeInput={onChangeInput}
              compareFormulasLayout={false}
              maxHeight={"100%"}
              maxWidth={"450px"}
              top={"-68px"}
              left={"-115px"}
              resultsUnitP={"12px"}
              resultsUnitPadding={"2px 5px"}
              resultsPadding={"15px"}
              overflowY={"none"}
              headerCost={false}
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              openModal={openModal}
              resultsDetailsMarginTop={"0"}
            />
          ) : (
            <ResultsTable
              filter={filter}
              headers={[...headers]}
              headersTitle="Formula *"
              data={
                onChangeInput.length == 0 ? [...results] : [...onChangeInput]
              }
              setOnChangeInput={setOnChangeInput}
              checkboxSummary={checkboxSummary}
              onChangeInput={onChangeInput}
              showDetails={showDetails}
              compareFormulasLayout={false}
              maxHeight={"100%"}
              maxWidth={"450px"}
              top={"-68px"}
              left={"-115px"}
              resultsUnitP={"12px"}
              resultsUnitPadding={"2px 5px"}
              resultsPadding={"15px"}
              overflowY={"none"}
              headerCost={false}
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              openModal={openModal}
              resultsDetailsMarginTop={"0"}
            />
          )}
        </div>
      </>
    );
  }

  function handleOpenModal() {
    setOpenModal(!openModal);
    setButtonDetails(!buttonDetails);
  }

  const getFormulas = async () => {
    const publicFormula = await firestore
      .collection("formulas")
      .where("public", "==", true)
      .get();

    const formulasPublic = publicFormula.docs.map((it) => ({
      id: it.id,
      ...it.data(),
    }));

    const myFormula = await firestore
      .collection("formulas")
      .where("email", "==", auth.currentUser?.email)
      .get();

    const formulasMy = myFormula.docs.map((it) => ({
      id: it.id,
      ...it.data(),
    }));

    const formulasMyPublic = [...formulasMy, ...formulasPublic];
    setFormulas(formulasMyPublic);
  };

  useEffect(() => {
    dispatch(Actions.compClear());

    formulasToCalculateAux.map((formulaCalculate, index) => {
      const formulaAux = {
        ...formulaCalculate,
        products: formulaCalculate.products.map((product) => {
          return {
            ...product,
            port:
              dropdownSelectedPOE && dropdownSelectedPOE.id
                ? dropdownSelectedPOE.id
                : null,
            transit:
              dropdownSelectedPOE?.country?.alphaCode !==
              dropDownSelectedDestination?.town?.country?.alphaCode
                ? "DIRECT"
                : "NONE",
          };
        }),
      };
      dispatch(Actions.compAddFormula(formulaAux));
    });
  }, [dropdownSelectedPOE, dropdownSelectedPOP, dropDownSelectedDestination]);

  function closeModal() {
    dispatch(Actions.compClear());
    setIsOpenModal(false);
  }

  useEffect(() => {
    getFormulas();
    if (formulasToCalculateAux) {
      setHasFormulasCompared(true);
    }
    return () => {
      dispatch(Actions.compClear());
    };
  }, []);

  // Updating selectDropdown values if it comes from the URL:
  useEffect(() => {
    setOptionsSelectedPOE(ports[0]);
    const found = window.location.search
      .replace("?", "")
      .split("&")
      .map((it) => it.split("="));
    if (found[0] && found[0][1] && !controlFoundPOE && ports[0].length > 0) {
      setDropdownSelectedPOE(ports[0].find((port) => port.id == found[0][1]));
      setControlFoundPOE(true);
    }
  }, [ports]);

  useEffect(() => {
    setOptionsSelectedPOP(placesOfBlending);
    const found = window.location.search
      .replace("?", "")
      .split("&")
      .map((it) => it.split("="));
    if (
      found[1] &&
      found[1][1] &&
      !controlFoundPOP &&
      placesOfBlending.length > 0
    ) {
      setDropdownSelectedPOP(
        placesOfBlending.find((place) => place.id == found[1][1])
      );
      setControlFoundPOP(true);
    }
  }, [placesOfBlending]);

  useEffect(() => {
    setOptionsSelectedDestination(destinations);
    const found = window.location.search
      .replace("?", "")
      .split("&")
      .map((it) => it.split("="));
    if (
      found[2] &&
      found[2][1] &&
      !controlFoundDES &&
      destinations.length > 0
    ) {
      setDropdownSelectedDestination(
        destinations.find((destination) => destination.id == found[2][1])
      );
      setControlFoundDES(true);
    }
  }, [destinations]);

  function handleShare() {
    const formatFormulas = formulasToCalculateAux.map((e) => {
      return {
        ...e,
      };
    });

    const formulasToCalculate = formatFormulas;

    firestore
      .collection("compareShares")
      .add({ formulasToCalculate })
      .then((ref) => setShareId(ref.id))
      .catch(errorHandler);
    setSharing(true);
  }

  const model = [
    {
      category: Category.CIF,
      label: t("CIF price at destination"),
    },
    {
      category: Category.Export,
      label: t("Prices ex-Port"),
    },
    {
      category: Category.DeliveredToBlendingFactory,
      label: t("Price delivered to the blending factory"),
    },
    {
      category: Category.DeliveredAtImporterWarehouse,
      label: t("Price delivered at the importer warehouse"),
    },
    {
      category: Category.ExImporterWarehouse,
      label: t("Prices ex importer warehouse"),
    },
    {
      category: Category.DeliveredAtWholesalerWarehouse,
      label: t("Price delivered at wholesaler warehouse"),
    },
    {
      category: Category.FinalPrices,
      label: t("Final Prices"),
    },
  ];

  function itemTemplate(model) {
    const { category, label } = model;

    function toggleItem(flag) {
      if (flag) {
        setFilter([...filter, category]);
      } else {
        setFilter(filter.filter((it) => it !== category));
      }
    }

    return (
      <div key={category}>
        <Checkbox
          checked={filter.some((it) => it === category)}
          onChange={(e) => toggleItem(e.checked)}
        />
        <span style={{ marginLeft: 2 }}>{label}</span>
      </div>
    );
  }

  function openModalSelectFormulas() {
    setIsOpenModal(true);
    setOnGetNewCosts(false);
  }

  return (
    <>
      <Toaster />
      <div className="all--wrapper desktop">
        <TemporaryEditor
          dropdownSelectedPOE={dropdownSelectedPOE}
          dropdownSelectedPOP={dropdownSelectedPOP}
          dropDownSelectedDestination={dropDownSelectedDestination}
          setFlagPOEtable={setFlagPOEtable}
          setFlagPOPtable={setFlagPOPtable}
          setFlagDEStable={setFlagDEStable}
          flagPOEtable={flagPOEtable}
          flagPOPtable={flagPOPtable}
          flagDEStable={flagDEStable}
        />
        {/*<EditProductDialog />*/}
        <div className="compareFormulas noPrint">
          <aside>
            <div className="Places">
              <button
                onClick={() => openModalSelectFormulas()}
                className={
                  hasFormulasCompared
                    ? "btn btn--blue selectFormulasBtn"
                    : "btn btn--blue selectFormulasBtn selectFormulasBtnAnimated"
                }
              >
                {t("Select Formulas")}
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="asideCompareWrapper">
              <img src={BusinessImg} />
              <div className="actionButtons Places">
                <h1>{t("Share and Print")}</h1>
                <div style={{ display: "flex", width: "100%" }}>
                  {hasFormulasCompared == true ? (
                    <>
                      <button
                        onClick={() => handleShare()}
                        className="btn btn--primary center"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={ShareIcon}
                          alt=""
                        />
                        <p>{t("Share")}</p>
                      </button>
                      <button
                        onClick={() => window.print()}
                        className="btn btnOrangeBg center"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={PrinterIcon}
                          alt=""
                        />
                        <p>{t("Print")}</p>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        disabled
                        onClick={() => handleShare()}
                        className="btn btn--primary center disabled"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={ShareIcon}
                          alt=""
                        />
                        <p>{t("Share")}</p>
                      </button>

                      <button
                        disabled
                        onClick={() => window.print()}
                        className="btn btnOrangeBg center disabled"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={PrinterIcon}
                          alt=""
                        />
                        <p>{t("Print")}</p>
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="actionButtons Places">
                <h1>{t("Filter and Show Details")}</h1>
                <div style={{ display: "flex", width: "100%" }}>
                  {hasFormulasCompared ? (
                    <>
                      <button
                        onClick={() => setShowDetails(!showDetails)}
                        className="btn btn--secondary center"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={DetailIcon}
                          alt=""
                        />
                        <p>
                          {!showDetails ? t("Show Details") : t("Hide Details")}
                        </p>
                      </button>
                      <div style={{ display: "flex", fontSize: "11px" }}>
                        {model.map(itemTemplate)}
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        disabled
                        onClick={() => setIsShowing(true)}
                        className="btn btn--blue center disabled"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={FilterIcon}
                          alt=""
                        />
                        <p>{t("Filter")}</p>
                      </button>
                      <button
                        disabled
                        onClick={() => handleOpenModal()}
                        className="btn btn--secondary center disabled"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={DetailIcon}
                          alt=""
                        />
                        <p>
                          {buttonDetails ? "Show" : "Hide"} {t("Details")}
                        </p>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </aside>
          <ShareDialog
            visible={sharing}
            setVisible={setSharing}
            dropdownSelectedPOE={dropdownSelectedPOE}
            dropdownSelectedPOP={dropdownSelectedPOP}
            dropDownSelectedDestination={dropDownSelectedDestination}
            shareId={shareId}
          />
          {isOpenModal && (
            <div className="modal">
              <div
                className="modalWrapper"
                style={{ width: "90%", display: "block" }}
              >
                <div
                  style={{
                    width: "100%",
                    padding: "20px 25px",
                    display: "flex",
                    maxHeight: "90%",
                  }}
                >
                  <div className="p-grid" style={{ flexGrow: 100 }}>
                    <div className={Styles.FormulaPicker}>
                      <h1 style={{ margin: 0, padding: 0 }}>
                        {t("Pick the formulas")}
                      </h1>
                      <span style={{ fontSize: ".75rem" }}>
                        {t("Use the")}{" "}
                        <i
                          className="fa fa-plus"
                          style={{ color: "#04ba56" }}
                        />{" "}
                        button
                      </span>
                      <ul style={{ padding: 0 }}>
                        {possibleFormulas.map((formula) => (
                          <li
                            className={Styles.FormulaItem}
                            onClick={() => cardSelected(formula)}
                          >
                            <span className={Styles.FormulaName}>
                              {formula.name}
                            </span>
                            <i
                              className={`fa fa-plus ${Styles.Plus}`}
                              title={`Add ${formula.name}`}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className={`p-col ${Styles.PickedFormulas}`}>
                      <h1 style={{ marginLeft: 15 }}>
                        {t("Formulas to Compare")}
                      </h1>

                      <div className="p-grid" style={{ overflowX: "hidden" }}>
                        {pickedFormulas.map((_, index) => (
                          <PickedFormulaItem index={index} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 25px",
                    marginBottom: 20,
                  }}
                >
                  <button
                    className="btn btn--secondary"
                    style={{ width: "20%" }}
                    onClick={() => closeModal()}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="btn btn--primary"
                    style={{ marginLeft: 10, width: "20%" }}
                    onClick={() => compareFormulas()}
                  >
                    {t("OK")}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="compareFormulas--main">
            <div className="compareFormulas--header">
              <div className="Places">
                <div className="PlacesStart">
                  <img src={AirplaneImg} alt="" />
                </div>
                <div className="PlacesEnd">
                  <h1>{t("Choose your preferences below")}</h1>

                  <div className="BlendingPosition">
                    <div className="BlendingAndDestination">
                      <SelectDropdown
                        myLabel={t("Departure City")}
                        inputName={"portOfEntry"}
                        myId={"searchbarPOE"}
                        myPlaceholder={t("Select the departure city")}
                        selectOptions={selectListPOE}
                        completeOptions={optionsSelectedPOE}
                        onSelectValue={handleChangeDropdown}
                        setValue={setDropdownSelectedPOE}
                        open={
                          formulasToCalculateAux.length &&
                          !dropdownSelectedPOE?.id &&
                          !isOpenModal
                            ? true
                            : false
                        }
                        value={dropdownSelectedPOE}
                      />
                    </div>
                    {/* <div className="BlendingAndDestination">
                      <SelectDropdown
                        myLabel={t("Place of Bagging or Blending")}
                        inputName={"placeOfProcessing"}
                        myId={"searchbarPOP"}
                        myPlaceholder={t("Select place of Processing")}
                        selectOptions={selectListPOP}
                        completeOptions={optionsSelectedPOP}
                        isInfo="Place of Processing"
                        onSelectValue={handleChangeDropdown}
                        setValue={setDropdownSelectedPOP}
                        open={formulasToCalculateAux.length && dropdownSelectedPOE?.id && !dropdownSelectedPOP?.id && !isOpenModal ? true : false}
                        value={dropdownSelectedPOP}
                      />
                    </div> */}
                    <div className="BlendingAndDestination">
                      <SelectDropdown
                        myLabel={t("Arrival City")}
                        inputName={"finalDestination"}
                        myId={"searchbarFD"}
                        myPlaceholder={t("Select the arrival city")}
                        selectOptions={selectListFD}
                        completeOptions={optionsSelectedDestination}
                        onSelectValue={handleChangeDropdown}
                        setValue={setDropdownSelectedDestination}
                        open={
                          formulasToCalculateAux.length &&
                          dropdownSelectedPOE?.id &&
                          dropdownSelectedPOP?.id &&
                          !dropDownSelectedDestination?.id &&
                          !isOpenModal
                            ? true
                            : false
                        }
                        value={dropDownSelectedDestination}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="compareFormulas--mainContent">
              {formulasToCalculateAux.length &&
              dropdownSelectedPOE?.id &&
              dropdownSelectedPOP?.id &&
              dropDownSelectedDestination?.id &&
              !isOpenModal ? (
                renderTable()
              ) : (
                <div className="compareFormulas--noFormulas">
                  <div>
                    <p style={{ fontSize: "20px", marginBottom: "15px" }}>
                      {t(
                        "This is the space for you to compare the formulas created and evaluate the best option."
                      )}
                    </p>
                    <h1>
                      {!formulasToCalculateAux.length
                        ? t("No Formulas are being compared")
                        : `${formulasToCalculateAux.length} Selected Formula${
                            formulasToCalculateAux.length > 1 ? "s" : ""
                          }`}
                    </h1>
                    <p
                      style={{
                        fontSize: "18px",
                        marginBottom: "15px",
                        color: "#31c5f4",
                      }}
                    >
                      {" "}
                      {formulasToCalculateAux.length
                        ? dropdownSelectedPOE?.id
                          ? // dropdownSelectedPOP?.id ?
                            dropDownSelectedDestination?.id
                            ? ""
                            : t("Please select a arrival city")
                          : // : t('Please select a place of bagging or blending')
                            t("Please select a departure city")
                        : t("Please select at least one formula")}
                    </p>
                  </div>
                  <img src={NoFormulasImage} alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="print" id="printable">
        <div className="logo">
          <img src={IfdcLogo} style={{ width: "120px" }} alt="" />
          <img src={UsaidLogo} style={{ width: "150px" }} alt="" />
          <img src={EcoWas} style={{ width: "120px" }} alt="" />
          <img src={UemoaLogo} style={{ width: "50px" }} alt="" />
          <img src={FeedIFuture} style={{ width: "150px" }} alt="" />
          <img src={CilssLogo} style={{ width: "50px" }} alt="" />
        </div>

        {checkboxSummary ? (
          <ResultsTable
            filter={filter}
            headers={[
              headersSummary[indexHeader],
              ...productsSummary.map(
                (it) =>
                  `${it.MAX.product.percentage}% ${it.MAX.product.type.name}`
              ),
            ]}
            headersTitle="Formula *"
            data={[
              onChangeInput.length == 0
                ? results[indexHeader]
                : onChangeInput[indexHeader],
              ...productsSummary
                .map((it) => it.MAX)
                .map((result) =>
                  Object.fromEntries(
                    Object.entries(result).filter(([k]) => sumKeys.includes(k))
                  )
                ),
            ]}
            showDetails={true}
            checkboxSummary={checkboxSummary}
            setOnChangeInput={setOnChangeInput}
          />
        ) : (
          <ResultsTable
            filter={filter}
            headers={[...headers]}
            headersTitle="Formula *"
            data={onChangeInput.length == 0 ? [...results] : [...onChangeInput]}
            setOnChangeInput={setOnChangeInput}
            showDetails={true}
            checkboxSummary={checkboxSummary}
          />
        )}
        <div
          style={{
            color: "#777",
            fontSize: "12px",
            borderTop: "0.3px solid #777",
          }}
        >
          {`User: ${auth.currentUser?.email} | Export Date: ${new Date()}`}
        </div>
        <div style={{ color: "#04ba56", fontSize: "12px", fontStyle: "bold" }}>
          These calculations were generated by the Fertilizer Cost Simulator
        </div>
        <div style={{ color: "#777", fontSize: "12px" }}>
          fertilizercostsimulator.ifdc.org
        </div>
      </div>

      <div className="all--wrapper mobile">
        <div className="compareFormulas noPrint">
          <aside className="desktop">
            <div className="Places">
              <button
                onClick={() => setIsOpenModal(true)}
                className={
                  hasFormulasCompared
                    ? "btn btn--blue selectFormulasBtn"
                    : "btn btn--blue selectFormulasBtn selectFormulasBtnAnimated"
                }
              >
                Select Formulas
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="asideCompareWrapper">
              <img src={BusinessImg} />
              <div className="actionButtons Places">
                <h1>{t("Share and Print")}</h1>
                <div>
                  {hasFormulasCompared == true ? (
                    <>
                      <button
                        onClick={() => handleShare()}
                        className="btn btn--primary center"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={ShareIcon}
                          alt=""
                        />
                        {t("Share")}
                      </button>
                      <button
                        onClick={() => window.print()}
                        className="btn btnOrangeBg center"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={PrinterIcon}
                          alt=""
                        />
                        <p>Print</p>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        disabled
                        onClick={() => handleShare()}
                        className="btn btn--primary center disabled"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={ShareIcon}
                          alt=""
                        />
                        <p>{t("Share")}</p>
                      </button>

                      <button
                        disabled
                        onClick={() => window.print()}
                        className="btn btnOrangeBg center disabled"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={PrinterIcon}
                          alt=""
                        />
                        <p>Print</p>
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="actionButtons Places">
                <h1>{t("Filter and Show Details")}</h1>
                <div>
                  {hasFormulasCompared ? (
                    <>
                      <button
                        onClick={() => setIsShowing(true)}
                        className="btn btn--blue center"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={FilterIcon}
                          alt=""
                        />
                        <p>Filter</p>
                      </button>
                      <button
                        onClick={() => setShowDetails(!showDetails)}
                        className="btn btn--secondary center"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={DetailIcon}
                          alt=""
                        />
                        <p>{buttonDetails ? "Show" : "Hide"} Details</p>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        disabled
                        onClick={() => setIsShowing(true)}
                        className="btn btn--blue center disabled"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={FilterIcon}
                          alt=""
                        />
                        <p>Filter</p>
                      </button>
                      <button
                        disabled
                        onClick={() => handleOpenModal()}
                        className="btn btn--secondary center disabled"
                      >
                        <img
                          style={{ maxWidth: "20px", marginRight: "8px" }}
                          src={DetailIcon}
                          alt=""
                        />
                        <p>{buttonDetails ? "Show" : "Hide"} Details</p>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </aside>
          <ShareDialog
            visible={sharing}
            setVisible={setSharing}
            dropdownSelectedPOE={dropdownSelectedPOE}
            dropdownSelectedPOP={dropdownSelectedPOP}
            dropDownSelectedDestination={dropDownSelectedDestination}
            shareId={shareId}
          />
          {isOpenModal && (
            <div className="modal">
              <div className="modalWrapper">
                <div className="filteringOptions">
                  <div className="filteringOptionsInside">
                    <div className="qntdSelectedCards">
                      <h1>Select Formulas</h1>
                      {formulasToCalculateAux.length > 0 ? (
                        <div>
                          <p>{formulasToCalculateAux.length} selected</p>
                        </div>
                      ) : null}
                    </div>
                    <p style={{ fontSize: "14px", color: "#444" }}>
                      Select the formulas you want to compare by clicking on it.
                      Then click the "Compare" button to compare them.
                    </p>
                  </div>
                  <div className="filteringOptionsInside">
                    <div className="cardsWrapper">
                      {formulas.map((formula, index) => (
                        <React.Fragment key={index}>
                          {formulasToCalculateAux.find(
                            (e) => e.id === formula.id
                          ) ? (
                            <div
                              className="formulaCards cardSelected"
                              onClick={() => cardSelectedMobile(formula)}
                            >
                              <img src={NPKBagIcon2} />
                              <h1>{formula.name}</h1>
                            </div>
                          ) : (
                            <div
                              className="formulaCards cardsNotSelected"
                              onClick={() => cardSelectedMobile(formula)}
                            >
                              <img src={NPKBagIcon} />
                              <h1>{formula.name}</h1>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  <div className="filteringOptionsInside">
                    <div className="div">
                      <button
                        className="btn btn--secondary"
                        onClick={() => closeModal()}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        className="btn btn--primary"
                        onClick={() => compareFormulas()}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="compareFormulas--main">
            <div className="compareFormulas--header">
              <div className="Places">
                <div className="PlacesEnd">
                  <p></p>
                  <div className="Places mobile">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <button
                        onClick={() => setIsOpenModal(true)}
                        className={
                          hasFormulasCompared
                            ? "btn btn--blue selectFormulasBtn"
                            : "btn btn--blue selectFormulasBtn selectFormulasBtnAnimated "
                        }
                      >
                        Select Formulas
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>
                      <button
                        onClick={() => setIsPlacesClicked(!isPlacesClicked)}
                        className="btn btn--primary selectFormulasBtn"
                      >
                        Select Places
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                  </div>
                  {isPlacesClicked && (
                    <div className="modal">
                      <div className="modalPlaces">
                        <div className="BlendingPosition">
                          <div className="PlacesStart">
                            <img src={AirplaneImg} alt="" />
                          </div>
                          <div className="BlendingAndDestination">
                            <SelectDropdownMobile
                              myLabel={t("Departure City")}
                              inputName={"portOfEntry"}
                              myId={"searchbarPOEMobile"}
                              myPlaceholder={"Select the departure city"}
                              selectOptions={selectListPOE}
                              completeOptions={optionsSelectedPOE}
                              onSelectValue={handleChangeDropdownMobile}
                              setValue={setDropdownSelectedPOE}
                              value={dropdownSelectedPOE}
                            />
                          </div>
                          {/* <div className="BlendingAndDestination">
                            <SelectDropdownMobile
                              myLabel={"Place of Bagging or Blending"}
                              inputName={"placeOfProcessing"}
                              myId={"searchbarPOPMobile"}
                              myPlaceholder={"Select place of Processing"}
                              selectOptions={selectListPOP}
                              completeOptions={optionsSelectedPOP}
                              isInfo="Place of Processing"
                              onSelectValue={handleChangeDropdownMobile}
                              setValue={setDropdownSelectedPOP}
                              value={dropdownSelectedPOP}
                            />
                          </div> */}
                          <div className="BlendingAndDestination">
                            <SelectDropdownMobile
                              myLabel={t("Arrival City")}
                              inputName={"finalDestination"}
                              myId={"searchbarFDMobile"}
                              myPlaceholder={"Select the arrival city"}
                              selectOptions={selectListFD}
                              completeOptions={optionsSelectedDestination}
                              onSelectValue={handleChangeDropdownMobile}
                              setValue={setDropdownSelectedDestination}
                              value={dropDownSelectedDestination}
                            />
                          </div>
                        </div>
                        <div
                          className="div"
                          style={{ display: "flex", gap: "8px" }}
                        >
                          <button
                            className="btn btn--secondary"
                            onClick={() => setIsPlacesClicked(false)}
                          >
                            {t("Cancel")}
                          </button>
                          <button
                            className="btn btn--primary"
                            onClick={() => setIsPlacesClicked(false)}
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="compareFormulas--mainContent">
              {formulasToCalculateAux.length
                ? dropdownSelectedPOP?.id
                  ? dropdownSelectedPOP?.id
                    ? renderTable()
                    : t("Please select a arrival city")
                  : // : t('Please select a place of bagging or blending')
                    t("Please select a departure city")
                : t("Please select at least one formula")}
            </div>
          </div>
          {isShowing && (
            <div className="modal">
              <div className="filterModal">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                    color: "#666",
                  }}
                >
                  <h3>Filtrar</h3>
                  <button onClick={() => setIsShowing(!isShowing)}>
                    <img src={XIcon} alt="X" />
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {model.map(itemTemplate)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="print mobile">
        <div className="logo">
          <img src={IfdcLogo} alt="" />
        </div>
      </div>

      {isSecondActionsOpen === false ? (
        <button
          onClick={() => setIsSecondActionsOpen(!isSecondActionsOpen)}
          className="pullPushIcon pullIcon mobile"
        >
          <img src={ArrowTwo} alt="" />
        </button>
      ) : (
        <bottom className="bottomCompare mobile">
          <button
            onClick={() => setIsSecondActionsOpen(!isSecondActionsOpen)}
            className="pullPushIcon closeIcon"
          >
            <img src={ArrowTwo} alt="" />
          </button>
          <div className="actionButtons Places">
            <h1>{t("Share and Print")}</h1>
            <div className="actionButtonsCompare">
              <button
                onClick={() => handleShare()}
                className="btn btn--primary center"
              >
                <img
                  style={{ maxWidth: "20px", marginRight: "8px" }}
                  src={ShareIcon}
                  alt=""
                />
                <p>{t("Share")}</p>
              </button>
              <button
                onClick={() => window.print()}
                className="btn btnOrangeBg center"
              >
                <img
                  style={{ maxWidth: "20px", marginRight: "8px" }}
                  src={PrinterIcon}
                  alt=""
                />
                <p>Print</p>
              </button>
            </div>
          </div>
          <div className="actionButtons Places">
            <h1>{t("Filter and Show Details")}</h1>
            <div className="actionButtonsCompare">
              <button
                onClick={() => setIsShowing(true)}
                className="btn btn--blue center"
              >
                <img
                  style={{ maxWidth: "20px", marginRight: "8px" }}
                  src={FilterIcon}
                  alt=""
                />
                <p>Filter</p>
              </button>
              <button
                onClick={() => setShowDetails(!showDetails)}
                className="btn btn--secondary center"
              >
                <img
                  style={{ maxWidth: "20px", marginRight: "8px" }}
                  src={DetailIcon}
                  alt=""
                />
                <p>{buttonDetails ? "Show" : "Hide"} Details</p>
              </button>
            </div>
          </div>
        </bottom>
      )}
    </>
  );
}
