import React from "react";
import { ApiContext } from "../../../../providers/ApiProvider";
import { NotificationContext } from "../../../../providers/NotificationProvider";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import GenericMaintenanceScreen from "./GenericMaintanceScreen";
import FloatLabel from "../../../utils/FloatLabel";
import useTowns from "../../../../hooks/useTowns";
import TownSelector from "../../../utils/selectors/TownSelector";
import useErrorHandler from "../../../../hooks/useErrorHandler";
import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import XIcon from "../../../../assets/images/XIcon.svg";
import { t } from "i18next";

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext);
  const notification = React.useContext(NotificationContext);
  const errorHandler = useErrorHandler(
    t("Something went wrong on managing the data")
  );
  const [loading, setLoading] = React.useState(false);
  const [triedOnce, setTriedOnce] = React.useState(false);

  const [statisticsDuty, setStatisticsDuty] = React.useState(0);
  const [solidarityLevy, setSolidarityLevy] = React.useState(null);
  const [ccvrLevy, setCcvrLevy] = React.useState(null);
  const [eximLevy, setEximLevy] = React.useState(null);
  const [townId, setTownId] = React.useState(null);

  const [town] = useTowns(townId);

  React.useEffect(refresh, [id]);

  function refresh() {
    setTriedOnce(false);
    setLoading(true);
    if (id) {
      api
        .getFinalDestination(id)
        .then(({ data }) => {
          setStatisticsDuty(data.statisticDuty);
          setSolidarityLevy(data.solidarityLevy);
          setCcvrLevy(data.ccvrLevy);
          setEximLevy(data.eximLevy);
          setLoading(false);
          setTownId(data.town?.id);
        })
        .catch((e) => {
          console.error(e);
          toast.error(
            t("Something went wrong on managing the data"),
            e.message
          );
        });
    } else {
      setStatisticsDuty(0);
      setSolidarityLevy(null);
      setCcvrLevy(null);
      setEximLevy(null);
      setLoading(null);
      setTownId(null);
    }
  }

  function finish() {
    toast.success(t("Destination saved successfully"), town?.name);
    onHide();
    notify();
  }

  function send() {
    setTriedOnce(true);
    if (town && typeof statisticsDuty === "number") {
      const payload = {
        statisticsDuty,
        solidarityLevy,
        ccvrLevy,
        eximLevy,
        town: town,
      };
      setLoading(true);
      if (id) {
        api
          .editFinalDestination(id, payload)
          .then(finish)
          .catch((e) => {
            console.error(e);
            notification.sendError(
              t("Something went wrong on saving the data"),
              e.message
            );
          });
      } else {
        api
          .addFinalDestination(payload)
          .then(finish)
          .catch((e) => {
            console.error(e);
            notification.sendError(
              t("Something went wrong on saving the data"),
              e.message
            );
          });
      }
    }
  }

  function deletedToast() {
    toast.success(t("Country deleted successfully"), town?.name);
    onHide();
    notify();
  }

  function handleDelete({ id, t }) {
    api.deleteFinalDestination(id).then(deletedToast).catch(errorHandler);
    toast.dismiss(t.id);
  }

  function askDelete() {
    toast((_t) => (
      <div className="deleteToastAdm">
        <h1>
          {t("Are you sure you want to delete")} <b>{town?.name}</b>?
        </h1>
        <p>{t("This is a one-way action!")}</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="btn btn--secondary"
            onClick={() => toast.dismiss(_t.id)}
          >
            {t("Cancel")}
          </button>
          <button
            style={{ marginLeft: "5px" }}
            className="btn btn--alert center"
            onClick={() => handleDelete({ id, _t })}
          >
            {t("Delete")}
          </button>
        </div>
      </div>
    ));
  }

  const header = (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{ margin: "0" }}>
            {id ? `${t("Editing")} ${town?.name}` : t("Creating")}
          </h1>
          <button className="xIconButton center" onClick={onHide}>
            <img src={XIcon} alt="" />
          </button>
        </div>
        <p>
          {id
            ? t("Select the options below to edit the Destination")
            : t("Select the options below to create the Destination")}
        </p>
      </div>
    </>
  );

  const body = (
    <>
      <div className="firstFloatLabel32">
        <label className="selectLabelAdm" htmlFor="town">
          {t("Town")}:
        </label>
        <br />
        <TownSelector
          id="town"
          value={townId}
          onChange={setTownId}
          disableNull
        />
      </div>

      <div className="firstFloatLabel32">
        <FloatLabel id="statistics" label={t("Statistics Duty")}>
          <InputNumber
            value={statisticsDuty}
            suffix="%"
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={3}
            onValueChange={(e) => setStatisticsDuty(e.value)}
          />
        </FloatLabel>
      </div>

      <div className="firstFloatLabel32">
        <FloatLabel id="solidarity" label={t("Solidarity Levy")}>
          <InputNumber
            value={solidarityLevy}
            suffix="%"
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={3}
            onValueChange={(e) => setSolidarityLevy(e.value)}
          />
        </FloatLabel>
      </div>

      <div className="firstFloatLabel32">
        <FloatLabel id="ccvr" label={t("CCVR Levy")}>
          <InputNumber
            value={ccvrLevy}
            suffix="%"
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={3}
            onValueChange={(e) => setCcvrLevy(e.value)}
          />
        </FloatLabel>
      </div>

      <FloatLabel id="exim" label={t("Exim Levy")}>
        <InputNumber
          value={eximLevy}
          suffix="%"
          mode="decimal"
          minFractionDigits={2}
          maxFractionDigits={3}
          onValueChange={(e) => setEximLevy(e.value)}
        />
      </FloatLabel>
    </>
  );

  const footer = (
    <>
      {id && !loading ? (
        <>
          <div style={{ width: "100%", display: "flex" }}>
            <button className="btn btn--alert center" onClick={askDelete}>
              {t("Delete")}
            </button>
            <button className="btn btn--primary" onClick={send}>
              {t("Save")}
            </button>
          </div>
        </>
      ) : (
        <>
          {!loading && (
            <button className="btn btn--primary" onClick={send}>
              {t("Create")}
            </button>
          )}
        </>
      )}
    </>
  );

  return (
    <Dialog
      className="modalAdmWrapper"
      onHide={onHide}
      visible={visible}
      header={header}
      footer={footer}
      draggable={false}
    >
      {loading ? (
        <div className="dfCenter">
          <ReactLoading
            type={"spinningBubbles"}
            color={"#31c5f4"}
            height={"40px"}
            width={"40px"}
          />
        </div>
      ) : (
        body
      )}
    </Dialog>
  );
}

export default function FinalDestinationMaintenance() {
  const api = React.useContext(ApiContext);

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  );

  function filterData(data, filterTable) {
    const dataFiltered =
      filterTable.length > 0
        ? data.filter(
            (e) =>
              (e.town &&
                e.town.name
                  .toLowerCase()
                  .includes(filterTable.toLowerCase())) ||
              e.statisticDuty.toString().includes(filterTable) ||
              e.solidarityLevy.toString().includes(filterTable) ||
              e.ccvrLevy.toString().includes(filterTable) ||
              e.eximLevy.toString().includes(filterTable)
          )
        : data;
    return dataFiltered;
  }

  return (
    <GenericMaintenanceScreen
      dataProvider={api.getFinalDestinations}
      renderEditDialog={dialog}
      filterData={filterData}
    >
      <Column field="id" header="Id" sortable />
      <Column field="statisticDuty" header={t("Statistic Duty")} sortable />
      <Column field="solidarityLevy" header={t("Solidarity Levy")} sortable />
      <Column field="ccvrLevy" header={t("CCVR Levy")} sortable />
      <Column field="eximLevy" header={t("Exim Levy")} sortable />
      <Column field="town.name" header={t("Town")} sortable />
      <Column
        field="town.country.displayName"
        header={t("Country")}
        body={({ town }) =>
          `${town.country.displayName} (${town.country.alphaCode})`
        }
        sortable
      />
    </GenericMaintenanceScreen>
  );
}
