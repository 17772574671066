import React from "react";
import { Dialog } from "primereact/dialog";
import XIcon from "../../../../assets/images/XIcon.svg";
import ShareIcon from "../../../../assets/images/shareIcon.png";
import {
  byId,
  queryRemoteFormulas,
} from "../../../../hooks/queryRemoteFormulas";
import {
  nowInUtc,
  useDeepCompareMemoized,
  utcToLocal,
} from "../../../../utils";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { NotificationContext } from "../../../../providers/NotificationProvider";
import copyToClipboard from "copy-to-clipboard";
import { OWN_FORMULAS } from "../../../../data";
import { firestore } from "../../../../services/firebase";
import firebase from "firebase";
import { Calendar } from "primereact/calendar";
import toast from "react-hot-toast";
import { t } from "i18next";

export default function ShareFormulaDialog({ id, visible, setVisible }) {
  const notification = React.useContext(NotificationContext);

  const initialFormula = queryRemoteFormulas([byId(id)]);
  const [formula, setFormula] = React.useState();
  const [copied, setCopied] = React.useState(false);

  const [shareUntil, setShareUntil] = React.useState(
    new Date(+new Date() + 86400000)
  );

  const isPublic = !!formula?.public;
  const isShared = (formula?.sharingUntil?.seconds || 0) > +nowInUtc() / 1000;

  React.useEffect(() => {
    firestore
      .collection("formulas")
      .doc(id)
      .onSnapshot((doc) => {
        setFormula({ id: doc.id, ...doc.data() });
      });
  }, [id]);

  React.useEffect(() => {
    setFormula(initialFormula);
  }, [useDeepCompareMemoized(initialFormula)]);

  function footer() {
    return (
      <>
        {!isPublic && isShared && (
          <Button
            label="Stop Sharing"
            className="p-button-danger"
            onClick={handleStopSharing}
          />
        )}
        {!isPublic && !isShared && (
          <Button
            label={t("Share")}
            className="p-button-success"
            onClick={handleShare}
          />
        )}
        <Button
          label={t("Cancel")}
          className="p-button-secondary"
          onClick={() => setVisible(false)}
        />
      </>
    );
  }

  function hide() {
    setVisible(false);
  }

  function renderIsShared() {
    const date = new Date(formula?.sharingUntil?.seconds * 1000);
    return isPublic ? (
      renderCopyToClipboard()
    ) : (
      <>
        {t("This formula is being shared until")}{" "}
        {utcToLocal(date).toLocaleDateString(undefined, {
          hour: "numeric",
          minute: "numeric",
        })}
        .
        <hr />
        {renderCopyToClipboard()}
      </>
    );
  }

  function renderNotShared() {
    return (
      <>
        <button onClick={() => hide()} className="buttonCloseSharing">
          <img src={XIcon} alt="X" />
        </button>
        {t("Your formula is not being shared")}
        <hr />
        <label style={{ fontWeight: "bold" }} htmlFor="calendar">
          {t("Share Until:")}
        </label>
        <br />
        <Calendar
          inputId="calendar"
          value={shareUntil}
          onChange={(e) => setShareUntil(e.value)}
          appendTo={document.body}
          showTime
        />
      </>
    );
  }

  function renderCopyToClipboard() {
    function copy() {
      setCopied(true);
      //notification.sendInfo('Copied to clipboard')
      toast.success(t("Copied to clipboard"));
      copyToClipboard(`${window.location.href}/edit/${formula?.id}`);
    }

    return (
      <>
        <label style={{ fontWeight: "bold" }}>{t("Copy to Clipboard:")}</label>
        <button onClick={() => hide()} className="buttonCloseSharing">
          <img src={XIcon} alt="X" />
        </button>
        <div className="p-inputgroup">
          <InputText
            contentEditable={false}
            value={`${window.location.href}/${formula?.id}`}
            style={{ fontSize: ".75rem" }}
          />
          <span
            className="p-inputgroup-addon"
            style={{
              cursor: "pointer",
              display: "inline",
              backgroundColor: copied ? "#04ba56" : "",
            }}
            onClick={() => copy()}
          >
            <i
              className="fa fa-copy"
              style={{
                transition: "backgroundColor 0.2s",
                color: copied ? "#ffffff" : "",
              }}
            />
          </span>
        </div>
      </>
    );
  }

  function handleStopSharing() {
    firestore
      .collection("formulas")
      .doc(id)
      .update({ sharingUntil: firebase.firestore.FieldValue.delete() })
      .then(() => {
        //notification.sendSuccess('Your formula is not being shared anymore')
        toast.success(t("Your formula is not being shared anymore"));
        OWN_FORMULAS.forget();
        localStorage.removeItem("@fcc/ownFormulas");
      })
      .catch((e) => {
        console.error(e);
        toast.error(t("Could not stop sharing your formula"));
        //notification.sendError('Could not stop sharing your formula')
      });
  }

  function handleShare() {
    firestore
      .collection("formulas")
      .doc(id)
      .update({
        sharingUntil: new firebase.firestore.Timestamp(+shareUntil / 1000, 0),
      })
      .then(() => {
        //notification.sendSuccess('Your formula was shared successfully', 'Copy the clipboard link and send to people')
        toast.success(
          t("Your formula was shared successfully"),
          t("Copy the clipboard link and send to people")
        );
        OWN_FORMULAS.forget();
        localStorage.removeItem("@fcc/ownFormulas");
      })
      .catch((e) => {
        console.error(e);
        toast.error(t("Could not share your formula"));
        //notification.sendError('Could not share your formula')
      });
  }

  return (
    <Dialog
      header={`${t("Share")} ${formula?.name}`}
      //footer={footer()}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      {isPublic || isShared ? renderIsShared() : renderNotShared()}
      {/*<div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Button label="Cancel" className="btn btn--secondary" onClick={() => setVisible(false)}/>
        { !isPublic && isShared && <Button label="Stop Sharing" className="btn btn--danger" onClick={handleStopSharing}/> }
        { !isPublic && !isShared && <Button label="Share" className="btn btn--primary" onClick={handleShare}/> }
      </div>*/}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          margin: "10px 0",
        }}
      >
        <button
          className="btn btn--secondary"
          onClick={() => setVisible(false)}
        >
          {t("Cancel")}
        </button>
        {!isPublic && isShared && (
          <button
            className="btn btn--alert center"
            onClick={() => handleStopSharing()}
            alt=""
          >
            <img
              style={{ maxWidth: "23px", marginRight: "10px" }}
              src={XIcon}
              alt=""
            />
            <p>{t("Stop Sharing")}</p>
          </button>
        )}
        {!isPublic && !isShared && (
          <button
            className="btn btn--primary center"
            onClick={() => handleShare()}
          >
            <img
              style={{ maxWidth: "23px", marginRight: "10px" }}
              src={ShareIcon}
              alt=""
            />
            <p>{t("Share")}</p>
          </button>
        )}
      </div>
    </Dialog>
  );
}
