import React, { useState } from "react";
import { NotificationContext } from "../../../../providers/NotificationProvider";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import AddIcon from "../../../../assets/images/AddIconWhite.png";
import CommonStyles from "../../../CommonStyles.module.scss";
import MinionsGroup from "../../../../assets/images/minionsGroup.svg";
import XIcon from "../../../../assets/images/XIcon.svg";
import { t } from "i18next";

export default function GenericMaintenanceScreen({
  dataProvider,
  renderEditDialog,
  children,
  filterData,
}) {
  const notification = React.useContext(NotificationContext);

  const [loading, setLoading] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [editingId, setEditingId] = React.useState();
  const [isInfoDisplayed, setIsInfoDisplayed] = useState(true);
  const [filterTable, setFilterTable] = React.useState("");

  const [data, setData] = React.useState();

  function startEdit(toEditId) {
    setEditingId(toEditId);
    setEditing(true);
  }

  function refresh() {
    setLoading(true);
    dataProvider()
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        notification.sendError(
          t("Something went wrong on fetching the data"),
          e.message
        );
      });
  }

  React.useEffect(refresh, []);

  const renderLoading = () => (
    <ProgressSpinner className={CommonStyles.CenterSpinner} />
  );

  const renderEditing = () =>
    renderEditDialog({
      id: editingId,
      onHide: () => {
        setEditingId(0);
        setEditing(false);
      },
      visible: editing,
      notify: refresh,
    });

  const dataFiltered = filterData(data, filterTable);

  const renderData = () => (
    <section>
      {renderEditing()}
      <div className={CommonStyles.TableButtons}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <button
            className="btn btn--primary center"
            onClick={() => startEdit()}
          >
            <img src={AddIcon} alt="" />
            <p>{t("Create")}</p>
          </button>
          <InputText
            id="inputSearchAdm"
            style={{ marginLeft: 20 }}
            placeholder={t("Search...")}
            value={filterTable}
            onChange={({ target }) => setFilterTable(target.value)}
          />
        </div>

        {isInfoDisplayed && (
          <div className={CommonStyles.InfoEditRows}>
            <img src={MinionsGroup} alt="" />
            <i>{t("Click in the rows to edit")}</i>
            <button onClick={() => setIsInfoDisplayed(false)}>
              <img src={XIcon} alt="" />
            </button>
          </div>
        )}
      </div>

      <DataTable
        value={dataFiltered}
        emptyMessage={t("No data to show")}
        onRowClick={({ data }) => startEdit(data.id)}
      >
        {children}
      </DataTable>
    </section>
  );

  return loading ? renderLoading() : renderData();
}
