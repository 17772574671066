import React from "react";

import { Dropdown } from "primereact/dropdown";
import { usePassageModes } from "../../../hooks";
import { t } from "i18next";

export default ({ id, value, onChange }) => {
  const [modes] = usePassageModes();

  return (
    <Dropdown
      id={id}
      value={value}
      options={modes}
      appendTo={document.body}
      placeholder={t("Select a Mode")}
      onChange={({ value }) => onChange(value)}
      style={{ width: "100%" }}
      optionValue="id"
      optionLabel="name"
    />
  );
};
