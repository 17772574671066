import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import "react-toggle/style.css";
import ReactLoading from "react-loading";
import firebase from "firebase/app";
import "firebase/firestore";
import { useIsAdmin } from "../../../hooks";
import NoResultsFound from "../../../assets/images/noResultsFound.svg";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";

export default function Feedback() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const firestore = firebase.firestore();
  const isAdmin = useIsAdmin();

  const fetchFeedbacks = async () => {
    setIsLoading(true);
    try {
      const feedback = await firestore.collection("feedback").get();

      const feedbackList = feedback.docs.map((it) => ({
        id: it.id,
        ...it.data(),
      }));

      setFeedbacks(feedbackList);
    } catch (error) {
      toast.error(
        t("An error occurred while getting feedback list. Please try again.")
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await firestore.collection("feedback").add({
        subject: subject,
        name: name,
        email: email,
        message: message,
        createdAt: new Date(),
      });

      toast.success(t("Feedback successfully sent!"));
      setSubject("");
      setName("");
      setEmail("");
      setMessage("");
      fetchFeedbacks();
    } catch (error) {
      console.error(t("Error submitting feedback: "), error);
      toast.error(
        t("An error occurred while submitting your feedback. Please try again.")
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async ({ id, t }) => {
    setIsLoading(true);

    try {
      await firestore.collection("feedback").doc(id).delete();
      fetchFeedbacks();
    } catch (error) {
      console.error(t("Error deleting feedback: "), error);
      toast.error(
        t("An error occurred while deleting your feedback. Please try again.")
      );
    } finally {
      toast.dismiss(t.id);
      setIsLoading(false);
    }
  };

  function askDelete(id) {
    toast((t) => (
      <div className="deleteToastAdm">
        <h1>{t("Are you sure you want to delete?")}</h1>
        <p>{t("This is a one-way action!")}</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="btn btn--secondary"
            onClick={() => toast.dismiss(t.id)}
          >
            {t("Cancel")}
          </button>
          <button
            style={{ marginLeft: "5px" }}
            className="btn btn--alert center"
            onClick={() => handleDelete({ id, t })}
          >
            {t("Delete")}
          </button>
        </div>
      </div>
    ));
  }

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  return (
    <>
      <Toaster />
      <div className="all--wrapper">
        <div className="formulasList">
          <div
            className="myFormulasHeader"
            style={{ height: "auto", padding: 20 }}
          >
            <div className="p-grid" style={{ alignItems: "start", gap: 20 }}>
              <div className="p-col">
                <h1 style={{ marginTop: 10, marginBottom: "20px" }}>
                  {t("Leave Your Feedback or Suggestion")}
                </h1>
                <p
                  style={{
                    marginBottom: 10,
                    color: "#f26101",
                    fontWeight: "bold",
                  }}
                >
                  {t("We value your feedback!")}
                </p>
                <p style={{ marginBottom: 10 }}>{t("yourInsights")}</p>
                <p>{t("Thank you for helping us grow!")}</p>
              </div>
              <div className="p-col">
                <form onSubmit={handleSubmit}>
                  <div style={{ marginBottom: "12px" }}>
                    <p style={{ color: "#555", fontSize: 14, fontWeight: 600 }}>
                      {t("Subject")}
                    </p>
                    <InputText
                      type="text"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                      style={{
                        width: "100%",
                        padding: "8px",
                        marginTop: "5px",
                        color: "#000",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginBottom: "12px",
                      display: "flex",
                      gap: 10,
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <p
                        style={{ color: "#555", fontSize: 14, fontWeight: 600 }}
                      >
                        {t("Name")}
                      </p>
                      <InputText
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        style={{
                          padding: "8px",
                          marginTop: "5px",
                          color: "#000",
                        }}
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <p
                        style={{ color: "#555", fontSize: 14, fontWeight: 600 }}
                      >
                        {t("Email")}
                      </p>
                      <InputText
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        style={{
                          padding: "8px",
                          marginTop: "5px",
                          color: "#000",
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <p style={{ color: "#555", fontSize: 14, fontWeight: 600 }}>
                      {t("Message")}
                    </p>
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                      rows="3"
                      style={{
                        width: "100%",
                        padding: "8px",
                        marginTop: "5px",
                        color: "#000",
                        fontSize: "1rem",
                        border: "1px solid #ced4da",
                        background: "#fff",
                        borderRadius: 3,
                        fontFamily: "inherit",
                        resize: "none",
                      }}
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    disabled={loading}
                    className="btn btn--primary"
                    style={{ width: "100%" }}
                  >
                    {loading ? t("Sending...") : t("Submit Feedback")}
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div className="myFormulasMain" style={{ height: "52%" }}>
            <div
              className="formulasMyPublicWrapperList"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h3 style={{ marginLeft: 20, marginTop: 20, color: "#555" }}>
                {t("Latest Feedback and Suggestions")}
              </h3>

              {feedbacks.length > 0 ? (
                feedbacks.map((feedback) => (
                  <div key={feedback.id} className="myFormulaUnitList">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 5,
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 22,
                          color: "#04ba56",
                          fontWeight: 600,
                        }}
                      >
                        {feedback.subject}
                      </p>

                      {isAdmin && (
                        <i
                          onClick={() => askDelete(feedback.id)}
                          className="fa fa-trash"
                          style={{
                            color: "#eb232d",
                            fontSize: 14,
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: 5,
                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <strong style={{ color: "#555" }}>{feedback.name}</strong>
                      <p style={{ fontSize: 14 }}>({feedback.email})</p>
                    </div>
                    <p
                      style={{
                        padding: "20px",
                        borderRadius: "4px",
                        backgroundColor: "#f6f6f6",
                      }}
                    >
                      {feedback.message}
                    </p>
                  </div>
                ))
              ) : isLoading ? (
                <div className="dfCenter">
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#31c5f4"}
                    height={"50px"}
                    width={"50px"}
                  />
                </div>
              ) : (
                <div className="noResultsFound">
                  <img src={NoResultsFound} alt="" />
                  <h1>{t("No results found!")}</h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
