import React, { useContext } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import { UserContext, UserProvider } from "./providers/UserProvider";
import { ApiProvider } from "./providers/ApiProvider";
import { NotificationProvider } from "./providers/NotificationProvider";
import HomePage from "../src/components/screen/UserDashboard/HomePage";
import LoginScreen from "./components/screen/auth/LoginScreen";
import ResetPassword from "./components/screen/auth/ResetPassword";
import RegisterScreen from "./components/screen/auth/RegisterScreen";
import EmailNotVerified from "./components/screen/auth/EmailNotVerified";
import AdminScreen from "./components/screen/AdminScreen";
import { UserDashboard } from "./components/screen/UserDashboard";
import useIsAdmin from "./hooks/useIsAdmin";
import { PUBLIC_FORMULAS } from "./data";
import "./hooks/translations/translation";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.min.css";

function ApplicationWrapper() {
  const user = useContext(UserContext);
  const isAdmin = useIsAdmin();

  React.useEffect(
    () => void setInterval(() => PUBLIC_FORMULAS.forget(), 60 * 1000),
    []
  );
  //console.log("user", user)

  const adminRoutes = (
    <>
      <Route path="/admin" component={AdminScreen} />
    </>
  );

  function renderLoggedIn() {
    return (
      <BrowserRouter>
        <Route path="/my" component={UserDashboard} />
        {isAdmin && adminRoutes}
      </BrowserRouter>
    );
  }

  function renderUserNotConfirmed() {
    return <EmailNotVerified />;
  }

  function renderNotLoggedIn() {
    return (
      <BrowserRouter>
        <Route path="/" exact component={HomePage} />
        <Route path="/login" component={LoginScreen} />
        <Route path="/resetPassword" component={ResetPassword} />
        <Route path="/register" component={RegisterScreen} />
      </BrowserRouter>
    );
  }

  return user
    ? user.emailVerified
      ? renderLoggedIn()
      : renderUserNotConfirmed()
    : renderNotLoggedIn();
}

function App() {
  return (
    <div className="App">
      <NotificationProvider>
        <UserProvider>
          <ApiProvider>
            <ApplicationWrapper />
          </ApiProvider>
        </UserProvider>
      </NotificationProvider>
    </div>
  );
}

export default App;
