import React, { useState } from "react";

import Styles from "./ResultsTable.module.scss";
import { useSelector } from "react-redux";
import { orGet } from "../calculation/results-utils";
import Tooltip from "rc-tooltip";
import { useTranslation } from "react-i18next";

export const Category = {
  CIF: 0,
  Export: 1,
  DeliveredToBlendingFactory: 2,
  DeliveredAtImporterWarehouse: 3,
  ExImporterWarehouse: 4,
  DeliveredAtWholesalerWarehouse: 5,
  FinalPrices: 6,
};

export function ResultsTable({
  data,
  filter,
  headers,
  headersTitle,
  showDetails,
  styles,
  setOnChangeInput,
  checkboxSummary,
}) {
  const { t } = useTranslation();
  const [checkedCIF, setCheckedCIF] = useState(true);
  const [checkedPricesExPort, setCheckedPricesExPort] = useState(true);
  const [checkedPriceBlendingFactory, setCheckedPriceBlendingFactory] =
    useState(true);
  const [checkedPriceImporterWarehouse, setCheckedPriceImporterWarehouse] =
    useState(true);
  const [checkedPriceExImporterWarehouse, setCheckedPriceExImporterWarehouse] =
    useState(true);
  const [checkedPriceDeliveredWarehouse, setCheckedPriceDeliveredWarehouse] =
    useState(true);
  const [checkedFinalPrices, setCheckedFinalPrices] = useState(true);
  const [buttonDetails, setButtonDetails] = useState(true);
  const [dataTable, setDataTable] = useState(data);

  React.useEffect(() => {
    setDataTable(data);
  }, [data]);

  const currency = useSelector((state) => state.currency.currency);
  const fcfa = useSelector((state) => state.currency.fcfa);
  const ghs = useSelector((state) => state.currency.ghs);
  const ngn = useSelector((state) => state.currency.ngn);

  function changeValue(value, field, index) {
    if (checkboxSummary) {
      return false;
    }

    if (isNaN(value)) return false;

    const getObject = dataTable[index];

    const changeArray = {
      ...getObject,
      [field]: value === "" ? 0 : parseFloat(value),
    };
    const sort = [
      ...dataTable.map((e, idx) => {
        if (idx !== index) {
          return e;
        } else {
          return changeArray;
        }
      }),
    ];

    setOnChangeInput(sort);
  }

  function extractValue(selector, bold, field, finalResult) {
    function select(it) {
      const selected = selector(it);

      if (
        selected === undefined ||
        (isNaN(selected) && typeof selected !== "string")
      ) {
        return undefined;
      } else {
        return typeof selected === "number" ? selected.toFixed(0) : selected;
      }
    }
    return dataTable.map((it, index) => {
      const selectedValue = select(it);

      if (selectedValue === undefined) {
        return undefined;
      } else {
        return bold ? (
          <th key={index} style={{ minWidth: "8rem" }}>
            {field ? (
              checkboxSummary ? (
                <Tooltip
                  placement="right"
                  trigger="hover"
                  overlay="Close the summary to edit the value"
                >
                  <input
                    style={{ fontWeight: "bold", color: "#444" }}
                    className={Styles.inputCostBreakDown}
                    onChange={(e) => changeValue(e.target.value, field, index)}
                    value={select(it)}
                  />
                </Tooltip>
              ) : (
                <input
                  style={{ fontWeight: "bold", color: "#444" }}
                  className={Styles.inputCostBreakDown}
                  onChange={(e) => changeValue(e.target.value, field, index)}
                  value={select(it)}
                />
              )
            ) : (
              <div
                style={{
                  fontStyle: "normal",
                  cursor: "default",
                  color: finalResult ? "#fff" : "#444",
                }}
              >
                {select(it)}
              </div>
            )}
          </th>
        ) : (
          <td style={{ minWidth: "8rem" }} key={index}>
            {field ? (
              checkboxSummary ? (
                <Tooltip
                  placement="right"
                  trigger="hover"
                  overlay="Close the summary to edit the value"
                >
                  <input
                    style={{ color: "#444" }}
                    className={Styles.inputCostBreakDown}
                    value={select(it)}
                    onChange={(e) => changeValue(e.target.value, field, index)}
                  />
                </Tooltip>
              ) : (
                <input
                  style={{ color: "#444" }}
                  className={Styles.inputCostBreakDown}
                  value={select(it)}
                  onChange={(e) => changeValue(e.target.value, field, index)}
                />
              )
            ) : (
              <div
                style={{
                  fontStyle: "normal",
                  cursor: "default",
                  color: finalResult ? "#fff" : "#444",
                }}
              >
                {select(it)}
              </div>
            )}
          </td>
        );
      }
    });
  }

  function details(label, selectValue, field) {
    return !showDetails ? null : (
      <tr
        className={Styles.Details}
        style={{ backgroundColor: field ? "#f1f3f4" : "none" }}
      >
        <td>{label}</td>
        {extractValue(selectValue, false, field)}
      </tr>
    );
  }
  const longestHeader = headers.reduce(
    (acc, it) => Math.max(acc, it.length),
    0
  );

  return (
    <div style={styles} className={Styles.TableWrapper}>
      <span
        className={`noPrint`}
        style={{ fontSize: "14px", marginBottom: "8px" }}
      >
        {t("AllValuesInUSD")}
      </span>
      <table className={Styles.Table}>
        <tr
          style={{
            backgroundColor: "#c1dcf8",
            position: "sticky",
            top: "-10px",
          }}
        >
          <th
            style={{
              minWidth: `${longestHeader * 1.25}rem`,
              padding: "5px 0px",
            }}
          >
            {headersTitle}
          </th>
          {headers.map((title, index) => (
            <th key={index}>{title}</th>
          ))}
        </tr>
        <tbody>
          {dataTable.some(
            (it) => it.blending || it.destination || it.portOfEntry
          ) && (
            <>
              <tr>
                <td>{t("Departure City")}</td>
                {extractValue((it) => it.portOfEntry)}
              </tr>
              {/* <tr>
                <td>Place of bagging or Blending</td>
                {extractValue((it) => it.blending)}
              </tr> */}
              <tr style={{ verticalAlign: "text-top" }}>
                <td style={{ height: "1.75rem" }}>{t("Arrival City")}</td>
                {extractValue((it) => it.destination)}
              </tr>
            </>
          )}

          {filter.length === 0 ||
            (filter.some((it) => it === Category.CIF) && (
              <>
                <tr>
                  <th>{t("FOBPriceAtOrigin")}</th>
                  {extractValue((it) => orGet(it.fobPriceAtOrigin), true)}
                </tr>

                <tr>
                  <td>{t("Freight")}</td>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) + orGet(it.seaFreightInsurrance),
                    false
                  )}
                </tr>

                {details(
                  t("FreightCharge"),
                  (it) => orGet(it.seaFreight),
                  "seaFreight"
                )}
                {details(t("Insurance"), (it) =>
                  orGet(it.seaFreightInsurrance)
                )}

                <tr>
                  <th>{t("CIFPriceAtDestination")}</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin),
                    true
                  )}
                </tr>
              </>
            ))}

          {filter.length === 0 ||
            (filter.some((it) => it === Category.Export) && (
              <>
                <tr>
                  <td>{t("PortCharges")}</td>
                  {extractValue(
                    (it) =>
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage)
                  )}
                </tr>

                {details(t("ChargesAndFeesAtPort"), (it) =>
                  orGet(it.chargesAndFeesAtPort)
                )}
                {details(t("ForwarderFeesPort"), (it) =>
                  orGet(it.portForwarderFees)
                )}
                {details(t("Demurrage"), (it) => orGet(it.demurrage))}

                <tr>
                  <td>{t("CustomCharges")}</td>
                  {extractValue((it) => {
                    return (
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy)
                    );
                  }, false)}
                </tr>

                {details(t("CustomLevy"), (it) => orGet(it.customLevy))}
                {details(t("ECOWAS_AfricanUnion"), (it) => orGet(it.ecowasAu))}
                {details(t("StatisticsLevy"), (it) => orGet(it.statisticLevy))}
                {details(t("SolidarityLevy"), (it) => orGet(it.solidarityLevy))}
                {details(t("CCVRLevy"), (it) => orGet(it.ccvrLevy))}
                {details(t("EximLevy"), (it) => orGet(it.eximLevy))}

                <tr>
                  <th>{t("PricesExPort")}</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage),
                    true
                  )}
                </tr>
              </>
            ))}

          {filter.length === 0 ||
            (filter.some(
              (it) => it === Category.DeliveredToBlendingFactory
            ) && (
              <>
                <tr>
                  <td>{t("DirectTransit")}</td>
                  {extractValue(
                    (it) =>
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees),
                    false
                  )}
                </tr>

                {details(t("GarantyFoundFGR"), (it) => orGet(it.garantyFound))}
                {details(
                  t("TransportToInland"),
                  (it) => orGet(it.transportToInland),
                  "transportToInland"
                )}
                {details(
                  t("OtherSmallTransitFees"),
                  (it) => orGet(it.otherSmallTransitFees),
                  "otherSmallTransitFees"
                )}

                <tr style={{ backgroundColor: "#f1f3f4" }}>
                  <td>{t("PortToWarehouse")}</td>
                  {extractValue(
                    (it) => orGet(it.portToWarehouse),
                    false,
                    "portToWarehouse"
                  )}
                </tr>

                <tr>
                  <th>{t("PriceDeliveredToBlendingFactory")}</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage) +
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees) +
                      orGet(it.portToWarehouse),
                    true
                  )}
                </tr>
              </>
            ))}

          {filter.length === 0 ||
            (filter.some(
              (it) => it === Category.DeliveredAtImporterWarehouse
            ) && (
              <>
                {details(t("CostIngredients"), (it) =>
                  orGet(it.costIngredients)
                )}
                {details(
                  t("CostProcess"),
                  (it) => orGet(it.costProccess),
                  "costProccess"
                )}
                {details(
                  t("FactoryDepreciation"),
                  (it) => orGet(it.factoryDepreciation),
                  "factoryDepreciation"
                )}

                <tr>
                  <th>{t("PriceExWorkFOT")}</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage) +
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees) +
                      orGet(it.costProccess) +
                      orGet(it.factoryDepreciation) +
                      orGet(it.portToWarehouse),
                    true
                  )}
                </tr>
              </>
            ))}

          {filter.length === 0 ||
            (filter.some((it) => it === Category.ExImporterWarehouse) && (
              <>
                <tr>
                  <td>{t("OverheadsImporter")}</td>
                  {extractValue((it) => {
                    return (
                      orGet(it.importerBankFees) +
                      orGet(it.importerBankInterest) +
                      orGet(it.importerStorageFees) +
                      orGet(it.importerHandlingFees) +
                      orGet(it.importerAdministrativeFees) +
                      orGet(it.importerInsurrance) +
                      orGet(it.importerLossOfWeight) +
                      orGet(it.importerRawMargin) +
                      orGet(it.importerTaxesOnMargin)
                    );
                  }, false)}
                </tr>

                {details(
                  t("BankFees"),
                  (it) => orGet(it.importerBankFees),
                  "importerBankFees"
                )}
                {details(
                  t("BankInterest"),
                  (it) => orGet(it.importerBankInterest),
                  "importerBankInterest"
                )}
                {details(
                  t("StorageFees"),
                  (it) => orGet(it.importerStorageFees),
                  "importerStorageFees"
                )}
                {details(
                  t("HandlingFees"),
                  (it) => orGet(it.importerHandlingFees),
                  "importerHandlingFees"
                )}
                {details(
                  t("AdministrativeFees"),
                  (it) => orGet(it.importerAdministrativeFees),
                  "importerAdministrativeFees"
                )}
                {details(
                  t("Insurance"),
                  (it) => orGet(it.importerInsurrance),
                  "importerInsurrance"
                )}
                {details(
                  t("LossOfWeight"),
                  (it) => orGet(it.importerLossOfWeight),
                  "importerLossOfWeight"
                )}
                {details(
                  t("RawMargin"),
                  (it) => orGet(it.importerRawMargin),
                  "importerRawMargin"
                )}
                {details(
                  t("TaxesOnMargin"),
                  (it) => orGet(it.importerTaxesOnMargin),
                  "importerTaxesOnMargin"
                )}

                <tr>
                  <th>{t("PricesExImporterWarehouse")}</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage) +
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees) +
                      orGet(it.costProccess) +
                      orGet(it.factoryDepreciation) +
                      orGet(it.importerBankFees) +
                      orGet(it.importerBankInterest) +
                      orGet(it.importerStorageFees) +
                      orGet(it.importerHandlingFees) +
                      orGet(it.importerAdministrativeFees) +
                      orGet(it.importerInsurrance) +
                      orGet(it.importerLossOfWeight) +
                      orGet(it.importerRawMargin) +
                      orGet(it.importerTaxesOnMargin) +
                      orGet(it.portToWarehouse),
                    true
                  )}
                </tr>
              </>
            ))}

          {filter.length === 0 ||
            (filter.some(
              (it) => it === Category.DeliveredAtWholesalerWarehouse
            ) && (
              <>
                <tr>
                  <td>{t("TransportToProductionArea")}</td>
                  {extractValue(
                    (it) =>
                      orGet(it.transport) +
                      orGet(it.customLandBorder) +
                      orGet(it.forwardingLandBorder)
                  )}
                </tr>

                {details(
                  t("Transport"),
                  (it) => orGet(it.transport),
                  "transport"
                )}
                {details(
                  t("CustomLandBorder"),
                  (it) => orGet(it.customLandBorder),
                  "customLandBorder"
                )}
                {details(
                  t("ForwardingLandBorder"),
                  (it) => orGet(it.forwardingLandBorder),
                  "forwardingLandBorder"
                )}

                <tr>
                  <th>{t("PriceDeliveredAtWholesalerWarehouse")}</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage) +
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees) +
                      orGet(it.costProccess) +
                      orGet(it.factoryDepreciation) +
                      orGet(it.importerBankFees) +
                      orGet(it.importerBankInterest) +
                      orGet(it.importerStorageFees) +
                      orGet(it.importerHandlingFees) +
                      orGet(it.importerAdministrativeFees) +
                      orGet(it.importerInsurrance) +
                      orGet(it.importerLossOfWeight) +
                      orGet(it.importerRawMargin) +
                      orGet(it.importerTaxesOnMargin) +
                      orGet(it.transport) +
                      orGet(it.customLandBorder) +
                      orGet(it.forwardingLandBorder) +
                      orGet(it.portToWarehouse),
                    true
                  )}
                </tr>

                <tr>
                  <td>{t("OverheadsWholesaler")}</td>
                  {extractValue((it) => {
                    return (
                      orGet(it.wholesalerStorageFees) +
                      orGet(it.wholesalerHandlingFees) +
                      orGet(it.wholesalerLossOfWeight) +
                      orGet(it.wholesalerRawMargin) +
                      orGet(it.wholesalerTaxesOnMargin)
                    );
                  }, false)}
                </tr>

                {details(
                  t("StorageFees"),
                  (it) => orGet(it.wholesalerStorageFees),
                  "wholesalerStorageFees"
                )}
                {details(
                  t("HandlingFees"),
                  (it) => orGet(it.wholesalerHandlingFees),
                  "wholesalerHandlingFees"
                )}
                {details(
                  t("LossOfWeight"),
                  (it) => orGet(it.wholesalerLossOfWeight),
                  "wholesalerLossOfWeight"
                )}
                {details(
                  t("RawMargin"),
                  (it) => orGet(it.wholesalerRawMargin),
                  "wholesalerRawMargin"
                )}
                {details(
                  t("TaxesOnMargin"),
                  (it) => orGet(it.wholesalerTaxesOnMargin),
                  "wholesalerTaxesOnMargin"
                )}
              </>
            ))}

          {filter.length === 0 ||
            (filter.some((it) => it === Category.FinalPrices) && (
              <>
                <tr
                  style={{
                    backgroundColor: "#04ba56",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  <th
                    style={{
                      padding: "5px 3px",
                      borderRadius: "4px 0px 0px 4px",
                      color: "#fff",
                    }}
                  >
                    {t("wholesale_prices_production_area")}
                  </th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage) +
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees) +
                      orGet(it.costProccess) +
                      orGet(it.factoryDepreciation) +
                      orGet(it.importerBankFees) +
                      orGet(it.importerBankInterest) +
                      orGet(it.importerStorageFees) +
                      orGet(it.importerHandlingFees) +
                      orGet(it.importerAdministrativeFees) +
                      orGet(it.importerInsurrance) +
                      orGet(it.importerLossOfWeight) +
                      orGet(it.importerRawMargin) +
                      orGet(it.importerTaxesOnMargin) +
                      orGet(it.transport) +
                      orGet(it.customLandBorder) +
                      orGet(it.forwardingLandBorder) +
                      orGet(it.portToWarehouse) +
                      orGet(it.wholesalerStorageFees) +
                      orGet(it.wholesalerHandlingFees) +
                      orGet(it.wholesalerLossOfWeight) +
                      orGet(it.wholesalerRawMargin) +
                      orGet(it.wholesalerTaxesOnMargin),
                    true,
                    false,
                    true
                  )}
                </tr>

                <tr style={{ height: "0.25rem" }} />

                {currency === "USD" && (
                  <tr
                    style={{
                      backgroundColor: "#04ba56",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <td
                      style={{
                        padding: "5px 3px",
                        borderRadius: "4px 0px 0px 4px",
                      }}
                    >
                      {t("wholesale_prices_production_area_usd")}
                    </td>
                    {extractValue(
                      (it) =>
                        (orGet(it.seaFreight) +
                          orGet(it.seaFreightInsurrance) +
                          orGet(it.fobPriceAtOrigin) +
                          orGet(it.customLevy) +
                          orGet(it.ecowasAu) +
                          orGet(it.statisticLevy) +
                          orGet(it.solidarityLevy) +
                          orGet(it.ccvrLevy) +
                          orGet(it.eximLevy) +
                          orGet(it.chargesAndFeesAtPort) +
                          orGet(it.portForwarderFees) +
                          orGet(it.demurrage) +
                          orGet(it.garantyFound) +
                          orGet(it.transportToInland) +
                          orGet(it.otherSmallTransitFees) +
                          orGet(it.costProccess) +
                          orGet(it.factoryDepreciation) +
                          orGet(it.importerBankFees) +
                          orGet(it.importerBankInterest) +
                          orGet(it.importerStorageFees) +
                          orGet(it.importerHandlingFees) +
                          orGet(it.importerAdministrativeFees) +
                          orGet(it.importerInsurrance) +
                          orGet(it.importerLossOfWeight) +
                          orGet(it.importerRawMargin) +
                          orGet(it.importerTaxesOnMargin) +
                          orGet(it.transport) +
                          orGet(it.customLandBorder) +
                          orGet(it.forwardingLandBorder) +
                          orGet(it.portToWarehouse) +
                          orGet(it.wholesalerStorageFees) +
                          orGet(it.wholesalerHandlingFees) +
                          orGet(it.wholesalerLossOfWeight) +
                          orGet(it.wholesalerRawMargin) +
                          orGet(it.wholesalerTaxesOnMargin)) /
                        20,
                      false,
                      false,
                      true
                    )}
                  </tr>
                )}

                {currency === "FCFA" && (
                  <tr
                    style={{
                      backgroundColor: "#04ba56",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <td
                      style={{
                        padding: "5px 3px",
                        borderRadius: "4px 0px 0px 4px",
                      }}
                    >
                      {t("wholesale_prices_production_area_fcfa")}
                    </td>
                    {extractValue(
                      (it) =>
                        (orGet(it.seaFreight) +
                          orGet(it.seaFreightInsurrance) +
                          orGet(it.fobPriceAtOrigin) +
                          orGet(it.customLevy) +
                          orGet(it.ecowasAu) +
                          orGet(it.statisticLevy) +
                          orGet(it.solidarityLevy) +
                          orGet(it.ccvrLevy) +
                          orGet(it.eximLevy) +
                          orGet(it.chargesAndFeesAtPort) +
                          orGet(it.portForwarderFees) +
                          orGet(it.demurrage) +
                          orGet(it.garantyFound) +
                          orGet(it.transportToInland) +
                          orGet(it.otherSmallTransitFees) +
                          orGet(it.costProccess) +
                          orGet(it.factoryDepreciation) +
                          orGet(it.importerBankFees) +
                          orGet(it.importerBankInterest) +
                          orGet(it.importerStorageFees) +
                          orGet(it.importerHandlingFees) +
                          orGet(it.importerAdministrativeFees) +
                          orGet(it.importerInsurrance) +
                          orGet(it.importerLossOfWeight) +
                          orGet(it.importerRawMargin) +
                          orGet(it.importerTaxesOnMargin) +
                          orGet(it.transport) +
                          orGet(it.customLandBorder) +
                          orGet(it.forwardingLandBorder) +
                          orGet(it.portToWarehouse) +
                          orGet(it.wholesalerStorageFees) +
                          orGet(it.wholesalerHandlingFees) +
                          orGet(it.wholesalerLossOfWeight) +
                          orGet(it.wholesalerRawMargin) +
                          orGet(it.wholesalerTaxesOnMargin)) *
                        (fcfa / 20),
                      false,
                      false,
                      true
                    )}
                  </tr>
                )}

                {currency === "GHS" && (
                  <tr
                    style={{
                      backgroundColor: "#04ba56",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <td
                      style={{
                        padding: "5px 3px",
                        borderRadius: "4px 0px 0px 4px",
                      }}
                    >
                      {t("wholesale_prices_production_area_ghs")}
                    </td>
                    {extractValue(
                      (it) =>
                        (orGet(it.seaFreight) +
                          orGet(it.seaFreightInsurrance) +
                          orGet(it.fobPriceAtOrigin) +
                          orGet(it.customLevy) +
                          orGet(it.ecowasAu) +
                          orGet(it.statisticLevy) +
                          orGet(it.solidarityLevy) +
                          orGet(it.ccvrLevy) +
                          orGet(it.eximLevy) +
                          orGet(it.chargesAndFeesAtPort) +
                          orGet(it.portForwarderFees) +
                          orGet(it.demurrage) +
                          orGet(it.garantyFound) +
                          orGet(it.transportToInland) +
                          orGet(it.otherSmallTransitFees) +
                          orGet(it.costProccess) +
                          orGet(it.factoryDepreciation) +
                          orGet(it.importerBankFees) +
                          orGet(it.importerBankInterest) +
                          orGet(it.importerStorageFees) +
                          orGet(it.importerHandlingFees) +
                          orGet(it.importerAdministrativeFees) +
                          orGet(it.importerInsurrance) +
                          orGet(it.importerLossOfWeight) +
                          orGet(it.importerRawMargin) +
                          orGet(it.importerTaxesOnMargin) +
                          orGet(it.transport) +
                          orGet(it.customLandBorder) +
                          orGet(it.forwardingLandBorder) +
                          orGet(it.portToWarehouse) +
                          orGet(it.wholesalerStorageFees) +
                          orGet(it.wholesalerHandlingFees) +
                          orGet(it.wholesalerLossOfWeight) +
                          orGet(it.wholesalerRawMargin) +
                          orGet(it.wholesalerTaxesOnMargin)) *
                        (ghs / 20),
                      false,
                      false,
                      true
                    )}
                  </tr>
                )}

                {currency === "NGN" && (
                  <tr
                    style={{
                      backgroundColor: "#04ba56",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <td
                      style={{
                        padding: "5px 3px",
                        borderRadius: "4px 0px 0px 4px",
                      }}
                    >
                      {t("wholesale_prices_production_area_ngn")}
                    </td>
                    {extractValue(
                      (it) =>
                        (orGet(it.seaFreight) +
                          orGet(it.seaFreightInsurrance) +
                          orGet(it.fobPriceAtOrigin) +
                          orGet(it.customLevy) +
                          orGet(it.ecowasAu) +
                          orGet(it.statisticLevy) +
                          orGet(it.solidarityLevy) +
                          orGet(it.ccvrLevy) +
                          orGet(it.eximLevy) +
                          orGet(it.chargesAndFeesAtPort) +
                          orGet(it.portForwarderFees) +
                          orGet(it.demurrage) +
                          orGet(it.garantyFound) +
                          orGet(it.transportToInland) +
                          orGet(it.otherSmallTransitFees) +
                          orGet(it.costProccess) +
                          orGet(it.factoryDepreciation) +
                          orGet(it.importerBankFees) +
                          orGet(it.importerBankInterest) +
                          orGet(it.importerStorageFees) +
                          orGet(it.importerHandlingFees) +
                          orGet(it.importerAdministrativeFees) +
                          orGet(it.importerInsurrance) +
                          orGet(it.importerLossOfWeight) +
                          orGet(it.importerRawMargin) +
                          orGet(it.importerTaxesOnMargin) +
                          orGet(it.transport) +
                          orGet(it.customLandBorder) +
                          orGet(it.forwardingLandBorder) +
                          orGet(it.portToWarehouse) +
                          orGet(it.wholesalerStorageFees) +
                          orGet(it.wholesalerHandlingFees) +
                          orGet(it.wholesalerLossOfWeight) +
                          orGet(it.wholesalerRawMargin) +
                          orGet(it.wholesalerTaxesOnMargin)) *
                        (ngn / 20),
                      false,
                      false,
                      true
                    )}
                  </tr>
                )}
              </>
            ))}
        </tbody>
      </table>
    </div>
  );
}
