import React, { useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { Menubar } from "primereact/menubar";
import { TieredMenu } from "primereact/tieredmenu";
import { auth } from "../../../services/firebase";
import noUserImg from "../../../assets/images/user.png";
import useIsAdmin from "../../../hooks/useIsAdmin";
import * as Actions from "../../../redux/actions";
import Styles from "./index.module.scss";
import { FormulaOverview } from "../FormulasPage/FormulaOverview/index";
import { ForumulaCalculator } from "../FormulaCalculator";
import { UserContext } from "../../../providers/UserProvider";
import CompareFormulas from "../CompareFormulas";
import HomeSystem from "./HomeSystem";
import AboutUs from "./AboutUs";
import { FormulaListPage } from "../FormulasPage/FormulaListPage";
import { FormulaCreation } from "../FormulasPage/FormulaCreation";
import ShareUrlHandler from "../CompareFormulas/ShareUrlHandler";
import SelectDropdownCurrency from "../../utils/SelectCurrency";
import { useDispatch, useSelector } from "react-redux";
import MoneyIcon from "../../../assets/images/moneyIcon.svg";
import { FormulaListPageCopy } from "../FormulasPage/FormulaListPage/indexCopy";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import usaFlag from "../../../assets/images/usa.png";
import franceFlag from "../../../assets/images/france.png";
import styleSelect from "./Home.module.scss";
import Feedback from "../Feedback";
import { useLocation } from "react-router-dom";
import LanguageSelector from "../../utils/LanguageSeleector";
import { t } from "i18next";

const cookies = new Cookies();

export function UserDashboard({ history, to, actviveOnlyWhenExact }) {
  // const dispatch = useDispatch();
  const location = useLocation();

  const isAdmin = useIsAdmin();
  const user = React.useContext(UserContext);
  const userMenuRef = React.useRef();
  const [currencyName, setCurrencyName] = useState("");
  let fcfa = useSelector((state) => state.currency.fcfa);
  let ghs = useSelector((state) => state.currency.ghs);
  let ngn = useSelector((state) => state.currency.ngn);

  const { path } = useRouteMatch();

  const { i18n } = useTranslation();

  function goTo(page) {
    //dispatch(Actions.setMenuHeader(page));
    history.push(`${path}${page}`);
  }

  const menuModel = [
    {
      label: t("Home"),
      command: () => goTo("/home"),
      className: location.pathname === "/my/home" && "menuItemSelected",
    },
    {
      label: t("Formulas"),
      command: () => goTo("/formula"),
      className: location.pathname === "/my/formula" && "menuItemSelected",
      disabled: true ? false : true,
    },
    {
      label: t("Compare Formulas"),
      command: () => goTo("/formulas/compare"),
      className:
        location.pathname === "/my/formulas/compare" && "menuItemSelected",
      disabled: true ? false : true,
    },
    {
      label: t("About Us"),
      command: () => goTo("/about"),
      className: location.pathname === "/my/about" && "menuItemSelected",
    },
    {
      label: t("Feedback and Suggestions"),
      command: () => goTo("/feedback"),
      className: location.pathname === "/my/feedback" && "menuItemSelected",
    },
  ];

  const userMenuModel = [
    {
      label: t("Compare Formulas"),
      command: () => goTo("/formulas/compare"),
    },
    {
      label: `${user.displayName}`,
      style: { textAlign: "center", cursor: "pointer" },
    },
    {
      separator: true,
    },
    {
      label: t("Administrative Dashboard"),
      icon: "fa fa-cog",
      style: { display: isAdmin ? "block" : "none" },
      url: "/admin/country",
    },
    {
      label: t("Quit"),
      icon: "fa fa-power-off",
      command: () => auth.signOut(),
    },
  ];

  const menubarStart = (
    <nav
      className="menuStart noPrint"
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <img
        className={Styles.logoNavbar}
        alt="IFDC Logo"
        src="/logo.png"
        onClick={() => goTo("/home")}
      />
    </nav>
  );

  const menubarEnd = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className={styleSelect.menuBarEnd} style={{ margin: 10 }}>
        <LanguageSelector />
      </div>
      <div className="menuCurrencyUser">
        <div>
          <h1 style={{ color: "#04ba56", fontSize: "12px", marginLeft: 5 }}>
            {currencyName}
          </h1>
        </div>
        <div className="moneyIconCurrency">
          <img src={MoneyIcon} alt="" />
        </div>
        <div>
          <SelectDropdownCurrency
            setCurrencyName={setCurrencyName}
            inputName={"currency"}
            myId={"searchbarCurrency"}
            currenciesList={[
              {
                name: "USD",
                value:
                  cookies.get("USD") === undefined ? 1 : cookies.get("USD"),
              },
              {
                name: "FCFA",
                value: fcfa,
              },
              {
                name: "GHS",
                value: ghs,
              },
              {
                name: "NGN",
                value: ngn,
              },
            ]}
          />
        </div>

        <nav className={Styles.MenubarEnd}>
          <img
            src={user.photoURL ? user.photoURL : noUserImg}
            onClick={(e) => userMenuRef.current?.toggle(e)}
            className={Styles.UserImg}
          />
          <TieredMenu
            className="tieredMenu"
            ref={userMenuRef}
            model={userMenuModel}
            popup
          />
        </nav>
      </div>
    </div>
  );

  return (
    <main className={Styles.UserDashboard}>
      <section className={Styles.PageWrapper}>
        <Menubar
          className={Styles.Header}
          model={menuModel}
          start={menubarStart}
          end={menubarEnd}
        ></Menubar>
        <section className={Styles.Page}>
          <Switch>
            <Route path="/my/home" component={HomeSystem} />
            <Route path="/my/formula/public">
              <FormulaListPage publicFormulas />
            </Route>
            <Route path="/my/formulacopy/">
              <FormulaListPageCopy />
            </Route>
            <Route
              path="/my/formulas/compare/shared"
              exact
              component={ShareUrlHandler}
            />
            <Route path="/my/formulas/compare" component={CompareFormulas} />
            <Route path="/my/formula/create/" component={FormulaCreation} />
            <Route
              path="/my/formula/edit/:formulaId"
              component={FormulaCreation}
            />
            <Route path="/my/formula/:formulaId" component={FormulaOverview} />
            <Route path="/my/formula" component={FormulaListPage} />
            <Route path="/my/calculate" component={ForumulaCalculator} />
            <Route path="/my/about" component={AboutUs} />
            <Route path="/my/feedback" component={Feedback} />
          </Switch>
        </section>
      </section>
    </main>
  );
}
